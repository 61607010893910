<div #searchBar class="search mx-auto p-1 d-flex justify-content-center align-items-center">
  <sk-svg-icon [cssIconSize]="'25px'" [icon]="'search'" [style.left]="'8px'" class="position-absolute icon ml-2"></sk-svg-icon>
  <input #searchInput (focus)="setHideContainer(false)" (input)="setSearchValue($event, searchInput.value)" type="text" class="form-control" placeholder="Suche">
  <button class="btn mx-2 btn-primary">Search</button>
  <ng-container *ngIf="!hideContainer && results && results.length > 0">
    <div (click)="setHideContainer(true)" class="sk-search-container-close-pane"></div>
    <div #resultContainer class="sk-search-result-container" [style.top]="(searchBar.clientHeight + 10) + 'px'">
      <ng-container  *ngFor="let result of results">
        <div *ngIf="result.type === 'offer' || result.type === 'category'" (click)="navigate(result)" class="w-100 d-flex flex-column p-2 sk-search-result">
          <div class="w-100 d-flex flex-row justify-content-start align-items-center">
            <div class="d-flex flex-row justify-content-start align-items-center sk-search-result-title">
              <span [innerText]="result.display"></span>
            </div>
            <div class="mx-1"></div>
            <div class="d-flex flex-row justify-content-start align-items-center sk-search-result-post-title">
              <span *ngIf="!result.display.includes(result.parent.attributes.name)" [innerText]="'in ' + result.parent.attributes.name"></span>
              <span *ngIf="result.display.includes(result.parent.attributes.name)"  [innerText]="'in ' + getParent(result.parent)?.attributes?.name || result.root.attributes.name"></span>
            </div>
          </div>
          <div *ngIf="result.type === 'offer'" class="w-100 d-flex flex-row justify-content-start align-items-center">
            <span [innerText]="result.object.attributes.description.length + ' Angebote ab ' + getLowestPrice(result)"></span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
