<div *ngIf="pageData" class="sk-static-page-container">

  <h1 [innerText]="pageData['title']"></h1>

  <p class="sk-p-head" [innerText]=""></p>

  <form #form ngNoForm (input)="onFormInput($event, mailInput, checkboxInput)"
        class="row g-3"
        action="https://sportkultour.us19.list-manage.com/subscribe/post?u=18c5b3b60fdca49b13316fea5&amp;id=7d702e2d15&amp;v_id=2716&amp;f_id=00ff9fe3f0"
        method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>

    <!-- eMail -->
    <div class="col-md-12 mb-3">
      <label for="inputEMail" class="form-label">
        {{pageData['eMail_checkbox_text']}}
        <ng-container [ngTemplateOutlet]="requirement" [ngTemplateOutletContext]="{required: true}"></ng-container>
      </label>
      <input #mailInput type="text" class="form-control" name="EMAIL" id="inputEMail">
    </div>

    <div class="col-md-12">
      {{pageData['marketing_permission_text']}}
    </div>

    <!-- Checkbox  -->
    <div class="col-12 mb-3">
      <div class="form-check">
        <input #checkboxInput class="form-check-input" type="checkbox" name="gdpr[98756]" id="inputTermsOfService">
        <label class="form-check-label" for="inputTermsOfService">
          {{pageData['eMail_checkbox_text']}}
          <ng-container [ngTemplateOutlet]="requirement" [ngTemplateOutletContext]="{required: true}"></ng-container>
        </label>
      </div>
    </div>

    <!-- Additional information -->
    <div #markdown>
      <markdown [data]="pageData['additional_information_text']"></markdown>
    </div>

    <button [disabled]="buttonDisabled"
            (click)="form.submit()"
            value="Subscribe" name="subscribe" id="mc-embedded-subscribe"
            type="submit" mat-flat-button color="primary">{{pageData['registration_button_text']}}</button>

  </form>

</div>

<ng-template #requirement let-required='required'>
  <span [style.padding-left]="'.25em'" [style.opacity]="'.85'">
    <span *ngIf="required">*</span>
    <span *ngIf="!required"> (optional)</span>
  </span>
</ng-template>
