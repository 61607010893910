import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sk-category',
  templateUrl: './sk-category.component.html',
  styleUrls: ['./sk-category.component.scss']
})
export class SkCategoryComponent implements OnInit {

  @Input() optionTitle: string = 'Option';

  constructor() { }

  ngOnInit(): void {
  }

}
