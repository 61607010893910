import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {DataService} from "../data/data.service";
import {ISKBookingRequest} from "../../views/sk-static-booking-request/sk-static-booking-request.component";

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  private readonly endpoint = environment.apiUrl + '/ezforms/submit/';

  constructor(
    private readonly dataService: DataService
  ) { }

  public sendBookingRequest(bookingRequest: ISKBookingRequest): Promise<unknown> {
    return this.dataService.post(this.endpoint, {formData: bookingRequest}).then((response: unknown) => {
      return response;
    })
  }

}
