import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnDestroy, OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ContentService, ISKRootCategory} from "../../services/content/content.service";
import {SkGenericCardComponent} from "../sk-generic-card/sk-generic-card.component";
import {CacheService} from "../../services/cache/cache.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'sk-side-menu',
  templateUrl: './sk-side-menu.component.html',
  styleUrls: ['./sk-side-menu.component.scss']
})
export class SkSideMenuComponent implements OnInit, OnDestroy {

  private _sideMenuCard: SkGenericCardComponent;
  @ViewChild('sideMenuCard') set sideMenuCard(sideMenuCard: SkGenericCardComponent) {
    this._sideMenuCard = sideMenuCard;
  };

  @Input() expanded: boolean = false;
  @Output() onItemClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  public content: ISKRootCategory[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    public readonly contentService: ContentService,
    public readonly cacheService: CacheService,
    private readonly cd: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.cacheService.content.subscribe((cachedContent: ISKRootCategory[]) => {
      this.setCachedContent(cachedContent).then(() => {
        console.log('[SkSideMenuComponent]: content (cached) updated: ', cachedContent);
      })
    }));
  }

  private async setCachedContent(cachedContent: ISKRootCategory[]): Promise<void> {
    this.content = null;
    this.cd.detectChanges();
    this.content = cachedContent;
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscriptions: Subscription) => {
      subscriptions.unsubscribe();
    });
  }

}
