<div *ngIf="pageData" class="sk-static-page-container">

  <h1 class="mb-4 user-select-none" (click)="setDummyData()" [innerText]="pageData['title']"></h1>

  <div class="pb-4" [innerText]="pageData['text']"></div>

  <form #form (change)="formChange($event)" [formGroup]="formControl" class="row g-3">

    <!-- firstName -->
    <div *ngVar="formControl.controls['firstName'] as firstNameControl" class="col-md-6">
      <label for="inputFirstName" class="form-label">
        {{pageData['firstName_text']}}
        <ng-container [ngTemplateOutlet]="requirement" [ngTemplateOutletContext]="{control: firstNameControl}"></ng-container>
      </label>
      <input [formControlName]="'firstName'" type="text" class="form-control" id="inputFirstName">
    </div>

    <!-- lastName -->
    <div *ngVar="formControl.controls['lastName'] as lastNameControl" class="col-md-6">
      <label for="inputLastName" class="form-label">
        {{pageData['lastName_text']}}
        <ng-container [ngTemplateOutlet]="requirement" [ngTemplateOutletContext]="{control: lastNameControl}"></ng-container>
      </label>
      <input [formControlName]="'lastName'" type="text" class="form-control" id="inputLastName">
    </div>

    <!-- eMail -->
    <div *ngVar="formControl.controls['eMail'] as eMailControl" class="col-md-12">
      <label for="inputEMail" class="form-label">
        {{pageData['eMail_text']}}
        <ng-container [ngTemplateOutlet]="requirement" [ngTemplateOutletContext]="{control: eMailControl}"></ng-container>
      </label>
      <input [formControlName]="'eMail'" type="text" class="form-control" id="inputEMail">
    </div>

    <!-- event -->
    <div *ngVar="formControl.controls['event'] as eventControl" class="col-md-6">
      <label for="inputEvent" class="form-label">
        {{pageData['event_text']}}
        <ng-container [ngTemplateOutlet]="requirement" [ngTemplateOutletContext]="{control: eventControl}"></ng-container>
      </label>
      <input [formControlName]="'event'" type="text" class="form-control" id="inputEvent">
    </div>

    <!-- eventDate -->
    <div *ngVar="formControl.controls['eventDate'] as eventDateControl" class="col-md-6">
      <label for="inputEventDate" class="form-label">
        {{pageData['eventDate_text']}}
        <ng-container [ngTemplateOutlet]="requirement" [ngTemplateOutletContext]="{control: eventDateControl}"></ng-container>
      </label>
      <input [formControlName]="'eventDate'" type="text" class="form-control" id="inputEventDate">
    </div>

    <!-- ticketCount -->
    <div *ngVar="formControl.controls['ticketCount'] as ticketCountControl" class="col-md-6">
      <label for="inputTicketCount" class="form-label">
        {{pageData['ticketCount_text']}}
        <ng-container [ngTemplateOutlet]="requirement" [ngTemplateOutletContext]="{control: ticketCountControl}"></ng-container>
      </label>
      <input [formControlName]="'ticketCount'" [min]="0" [max]="999" type="number" step="1" class="form-control" id="inputTicketCount">
    </div>

    <!-- ticketCategory -->
    <div *ngVar="formControl.controls['ticketCategory'] as ticketCategoryControl" class="col-md-6">
      <label for="inputTicketCategory" class="form-label">
        {{pageData['ticketCategory_text']}}
        <ng-container [ngTemplateOutlet]="requirement" [ngTemplateOutletContext]="{control: ticketCategoryControl}"></ng-container>
      </label>
      <input [formControlName]="'ticketCategory'" type="text" step="1" class="form-control" id="inputTicketCategory">
    </div>

    <!-- budget -->
    <div *ngVar="formControl.controls['budget'] as budgetControl" class="col-md-6">
      <label for="inputBudget" class="form-label">
        {{pageData['budget_text']}}
        <ng-container [ngTemplateOutlet]="requirement" [ngTemplateOutletContext]="{control: budgetControl}"></ng-container>
      </label>
      <input [formControlName]="'budget'" [min]="0" [max]="1000000" type="number" step="1" class="form-control" id="inputBudget">
    </div>

    <!-- services -->
    <div *ngVar="formControl.controls['services'] as servicesControl" class="col-md-6">
      <label for="inputServices" class="form-label">
        {{pageData['services_text']}}
        <ng-container [ngTemplateOutlet]="requirement" [ngTemplateOutletContext]="{control: servicesControl}"></ng-container>
      </label>
      <select [formControlName]="'services'" id="inputServices" class="form-select">
        <option selected [value]="serviceOptions[0].value">{{serviceOptions[0].display}}</option>
        <option *ngFor="let so of serviceOptions.slice(1); index as idx" [value]="so.value">{{so.display}}</option>
      </select>
    </div>

    <!-- privacyPolicy -->
    <div *ngVar="formControl.controls['privacyPolicy'] as privacyPolicyControl" class="col-12">
      <div class="form-check">
        <input [formControlName]="'privacyPolicy'" class="form-check-input" type="checkbox" id="inputPrivacyPolicy">
        <label class="form-check-label" for="inputPrivacyPolicy">
          {{pageData['privacy_policy_text']}}
          <ng-container [ngTemplateOutlet]="requirement" [ngTemplateOutletContext]="{control: privacyPolicyControl}"></ng-container>
        </label>
      </div>
    </div>

    <!-- termsOfService -->
    <div *ngVar="formControl.controls['termsOfService'] as termsOfServiceControl" class="col-12">
      <div class="form-check">
        <input [formControlName]="'termsOfService'" class="form-check-input" type="checkbox" id="inputTermsOfService">
        <label class="form-check-label" for="inputTermsOfService">
          {{pageData['tos_text']}}
          <ng-container [ngTemplateOutlet]="requirement" [ngTemplateOutletContext]="{control: termsOfServiceControl}"></ng-container>
        </label>
      </div>
    </div>

    <!-- Submit Button -->
    <div class="w-100 d-flex flex-row justify-content-start align-items-center user-select-none">

      <button mat-flat-button (click)="formControl.valid ? formSubmitted(form) : null" [disabled]="requestOngoing || !formControl.valid" color="primary">
        <span [style.opacity]="requestOngoing ? '0' : '1'" [innerText]="pageData['send_form_text']"></span>
        <mat-spinner *ngIf="requestOngoing"></mat-spinner>
      </button>

    </div>
  </form>

</div>

<ng-template #requirement let-control='control'>
  <span *ngVar="isRequired(control) as r" [style.padding-left]="'.25em'" [style.opacity]="'.85'">
    <span *ngIf="r">*</span>
    <span *ngIf="!r" [innerText]="pageData['optional_text']"></span>
  </span>
</ng-template>

