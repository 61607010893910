import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UiService} from "../../services/ui/ui.service";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import {UtilsService} from "../../services/utils/utils.service";
import {BookingService} from "../../services/booking/booking.service";
import {
  ISKCategoryData, ISKCategoryImageData,
  ISKOfferDataDescription
} from "../../services/content/content.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {ISKCachedCategoryData, ISKCachedOfferData} from "../../services/cache/cache.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {BaseView} from "../baseView";
import {DataService} from "../../services/data/data.service";
import {
  ISKGenericStaticPageAttributes, ISKStaticPageData,
  ISKStaticPages,
  StaticPagesService
} from "../../services/static-pages/static-pages.service";

@Component({
  selector: 'sk-static-booking-request',
  templateUrl: './sk-static-booking-request.component.html',
  styleUrls: ['./sk-static-booking-request.component.scss']
})
export class SkStaticBookingRequestComponent extends BaseView implements OnInit {

  public requestOngoing = this.dataService.requestOngoing.value;
  public pageData: ISKGenericStaticPageAttributes | undefined;
  public readonly pageTitle = 'Buchungsanfrage';
  public readonly formControl: FormGroup = this.formBuilder.group(({
    firstName: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(32)] ],
    lastName: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(32)] ],
    event: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(32)] ],
    eventDate: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(32)] ],
    eMail: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(64), Validators.email] ],
    ticketCount: ["", [Validators.required, Validators.min(1), Validators.max(64)] ],
    ticketCategory: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(64)] ],
    budget: ["", [Validators.min(1), Validators.max(1000000)] ],
    services: ["", [Validators.required] ],
    privacyPolicy: ["", [Validators.required, Validators.requiredTrue] ],
    termsOfService: ["", [Validators.required, Validators.requiredTrue] ],
  }));
  public readonly serviceOptions: ISKServiceOption[] = [];
  private readonly endpoint = this.activatedRoute.snapshot.data['endpoint'];

  constructor(
    public readonly ui: UiService,
    private readonly utils: UtilsService,
    private readonly dataService: DataService,
    private readonly formBuilder: FormBuilder,
    private readonly bookingService: BookingService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly activatedRoute: ActivatedRoute,
    private readonly staticPagesService: StaticPagesService,
    private readonly cd: ChangeDetectorRef
  ) {
    super();
    this.initialNavigation();
    this.subscriptions.push(this.staticPagesService.staticPageData.subscribe((staticPages: ISKStaticPages) => {
      this.pageData = staticPages[this.endpoint]?.attributes;
      this.serviceOptions.push(
        {
          value: 'ticketsOnly',
          display: this.pageData['services_ticketsOnly_text']
        },
        {
          value: 'ticketsAndTraveling',
          display: this.pageData['services_ticketsAndTraveling_text']
        }
      );

      setTimeout(() => {
        console.log('[SkStaticBookingRequestComponent] pageData: ', this.pageData);
      }, 2500);
    }));
  }

  ngOnInit(): void {
    this.dataService.requestOngoing.subscribe((r: boolean) => {
      this.requestOngoing = r;
    });
    this.cd.reattach();
  }

  private initialNavigation(): void {
    try {
      const state = this.router?.getCurrentNavigation()?.extras?.state;
      //console.log('initialNavigation:', state);
      const offer = state ? state['offer'] as {offer: ISKCachedOfferData, parent: ISKCachedCategoryData, packageId: number} : null;
      if (offer?.offer) {
        this.presetData(offer.offer, offer.parent, offer.packageId);
      }
    } catch (err) {
      if (!environment.production) {
        this.snackBar.open('Formular konnte nicht vorausgefüllt werden');
        console.error('[SkStaticBookingRequestComponent] initialNavigation failed: ', err);
      }
    } finally {
      // Clear router state
      this.router.navigate([], {state: null}).then(() => {
        if (!environment.production) {
          //console.log('[SkStaticBookingRequestComponent] router state cleared: ', this.router?.getCurrentNavigation()?.extras?.state);
        }
      })
    }
  }

  private presetData(offer: ISKCachedOfferData, parent?: ISKCategoryData, packageId?: number): void {
    try {
      const description: ISKOfferDataDescription = ((packageId > -1) && offer.attributes?.description?.length > 0) ? offer.attributes?.description[packageId] : null;
      this.formControl.controls['event'].setValue(offer.attributes.name);
      this.formControl.controls['eventDate'].setValue(offer.attributes.date);
      this.formControl.controls['ticketCategory'].setValue(description?.package_title);
    } catch (err) {
      console.error(err)
    }
  }

  public openSnackBar(msg: 'requestSent' | 'success' | 'failure'): void {
    let message;
    switch(msg) {
      case "requestSent":
        message = 'Ihre Buchungsanfrage wird gesendet';
        break;
      case "success":
        message = '✔️ Ihre Buchungsanfrage wurde erfolgreich versendet';
        break;
      case "failure":
        message = 'Ihre Buchungsanfrage konnte nicht versendet werden. Bitte versuchen Sie es erneut.';
        break;
    }
    this.snackBar.open(message, 'OK', this.ui.snackBarConfig);
  }

  public setDummyData(): void {
    if (!environment.production) {
      this.formControl.controls['firstName'].setValue('Hans');
      this.formControl.controls['lastName'].setValue('Müller');
      this.formControl.controls['eMail'].setValue('h.mueller@test.lol');
      this.formControl.controls['event'].setValue('Event XY');
      this.formControl.controls['eventDate'].setValue('Irgendwann Mittwoch oder so');
      this.formControl.controls['ticketCount'].setValue(2);
      this.formControl.controls['ticketCategory'].setValue('Veranstaltungskategorie XY');
      this.formControl.controls['budget'].setValue(1000);
      this.formControl.controls['services'].setValue(this.serviceOptions[1].value);
      this.formControl.controls['privacyPolicy'].setValue(true);
      this.formControl.controls['termsOfService'].setValue(true);
    }
  }

  formChange($event: Event): void {
    //console.log('[SkStaticBookingRequestComponent] Form changed:', this.formControl, $event);
    //console.log('[SkStaticBookingRequestComponent] Form validation:', this.formControl.valid);
  }

  mock() {
    this.openSnackBar('requestSent');
    this.dataService.requestOngoing.next(true);
    setTimeout(() => {
      this.dataService.requestOngoing.next(false);
      this.openSnackBar('success');
    }, 2500);
  }

  formSubmitted(form: HTMLFormElement): void {
    //console.log('Submitted: ', form, this.formControl);
    const bookingRequest: ISKBookingRequest = {
      firstName: this.formControl.controls['firstName'].value,
      lastName: this.formControl.controls['lastName'].value,
      eMail: this.formControl.controls['eMail'].value,
      event: this.formControl.controls['event'].value,
      eventDate: this.formControl.controls['eventDate'].value,
      ticketCount: this.formControl.controls['ticketCount'].value,
      ticketCategory: this.formControl.controls['ticketCategory'].value,
      budget: this.formControl.controls['budget'].value,
      services: this.formControl.controls['services'].value,
    }
    //this.mock();
    this.bookingService.sendBookingRequest(bookingRequest)
      .then((response: unknown) => {
        this.openSnackBar('success');
      })
      .catch((response: unknown) => {
        this.openSnackBar('failure');
      });
    return;
  }

  isRequired(control: AbstractControl) {
   return control.hasValidator(Validators.required);
  }

}

export interface ISKServiceOption {
  value: 'ticketsOnly' | 'ticketsAndTraveling',
  display: string
}

export interface ISKBookingRequest {
  firstName: string;
  lastName: string;
  eMail: string;
  event: string;
  eventDate: string;
  ticketCount: number;
  ticketCategory: string;
  budget: number;
  services: string;
}
