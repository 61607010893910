<div *ngIf="pageData" class="sk-static-page-container">
  <h1 class="mb-4 user-select-none" [innerText]="pageData.title"></h1>
  <markdown [data]="pageData['text']"></markdown>

  <!-- Partner logos -->
  <ng-container *ngIf="logoImages && logoImages.length > 0">
    <div class="row w-100 my-2 p-2 partner-logos">
      <div class="partner-logo position-relative col-4 col-sm-4 col-md-2" *ngFor="let logoImage of logoImages">
        <img [src]="logoImage" [alt]="logoImage.split('/').reverse()[0]">
      </div>
    </div>
  </ng-container>

  <markdown [data]="pageData['info']"></markdown>
</div>
