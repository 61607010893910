import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'sk-svg-icon',
  templateUrl: './sk-svg-icon.component.html',
  styleUrls: ['./sk-svg-icon.component.scss']
})
export class SkSvgIconComponent implements OnInit {

  @Input() icon = 'default.svg';
  @Input() cssElementSize = '2em';
  @Input() cssColor = '';
  @Input() cssDisplay = 'block';
  @Input() cssIconSize = '90%';
  @Input() cssPosition = 'relative';

  private readonly assetsUrl = (environment.domain + '/assets/icons/svg/');

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer2: Renderer2
  ) { }

  ngOnInit(): void {
    const self = this.elementRef.nativeElement;
    const iconUri = this.assetsUrl + (this.icon.includes('.svg') ? this.icon : this.icon + '.svg');
    this.renderer2.setStyle(self, 'position', this.cssPosition);
    this.renderer2.setStyle(self, 'display', this.cssDisplay);
    this.renderer2.setStyle(self, 'background', this.cssColor.length > 0 ? this.cssColor : null);
    this.renderer2.setStyle(self, 'width', this.cssElementSize);
    this.renderer2.setStyle(self, 'height', this.cssElementSize);
    this.renderer2.setStyle(self, 'mask-image', 'url(' + iconUri + ')');
    this.renderer2.setStyle(self, '-webkit-mask-image', 'url(' + iconUri + ')');
    this.renderer2.setStyle(self, 'mask-size', this.cssIconSize);
    this.renderer2.setStyle(self, '-webkit-mask-size', this.cssIconSize);
    this.renderer2.setStyle(self, 'mask-repeat', 'no-repeat');
    this.renderer2.setStyle(self, '-webkit-mask-repeat', 'no-repeat');
    this.renderer2.setStyle(self, 'mask-position', 'center');
    this.renderer2.setStyle(self, '-webkit-mask-position', 'center');
  }

}
