<div class="sk-offer-card-container"
     [style.text-align]="textAlign"
     [class.left-border]="leftBorder" [class.card-border]="cardBorder" [class.full-width]="ui.width < 1200">
  <div [style.display]="leftBorder ? 'block' : 'none'" class="left-border-stripe"></div>
  <div class="w-100 d-flex flex-row align-items-center justify-content-between">
    <div [class.title-border]="titleBorder" *ngIf="cardTitle.length > 0" class="sk-offer-card-container-title">{{cardTitle}}</div>
    <div *ngIf="cardTitle.length > 0" class="sk-offer-card-container-date">{{cardAnnotation}}</div>
  </div>
<!--  <div #offerCardImage *ngIf="imageUrl" class="sk-offer-card-image"-->

<!--       [style.background-image]="'url(' + imageUrl + ')'"></div>-->
<!--  <div class="sk-offer-card-content">-->
<!--    <ng-content></ng-content>-->
<!--  </div>-->
  <div class="sk-offer-card-options" *ngIf="offerOptions && offerOptions.length > 0">
    <sk-offer-option *ngFor="let offerOption of offerOptions"
                     [style.width]="(offerOptions.length < 2) || (ui.width < 1200) ? '100%' : '50%'"
                     [offerMarkdown]="offerOption.package"
                     [hideDescription]="hideDescription"
                     [priceInfo]="offerOption.price_info" [text]="offerOption.package" [price]="offerOption.price || offerOption.price_alternative_text"></sk-offer-option>
  </div>
</div>
