import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {Subscription} from "rxjs";
import {UiService} from "../../services/ui/ui.service";
import {ISKCategoryData, ISKHighlight, ISKRootCategory} from "../../services/content/content.service";
import {Router} from "@angular/router";
import {CacheService} from "../../services/cache/cache.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'sk-carousel',
  templateUrl: './sk-carousel.component.html',
  styleUrls: ['./sk-carousel.component.scss']
})
export class SkCarouselComponent implements AfterViewInit {

  @Input() highlights: ISKHighlight[] = [];

  private _skCarouselTimerBar: ElementRef<HTMLDivElement>;
  @ViewChild('skCarouselTimerBar') private set skCarouselTimerBar(skCarouselTimerBar: ElementRef<HTMLDivElement>) {
    this._skCarouselTimerBar = skCarouselTimerBar;
    this.renderer.addClass(skCarouselTimerBar.nativeElement, 'ani');
  };

  private _carouselNavigation: ElementRef<HTMLDivElement>;
  @ViewChild('carouselNavigation') private set carouselNavigation(carouselNavigation: ElementRef<HTMLDivElement>) {
    this._carouselNavigation = carouselNavigation;
    this.carouselNavigationAdjustment(carouselNavigation);
  };

  private subscriptions: Subscription[] = [];
  private interval: NodeJS.Timer | undefined;
  public carouselIdx: number = 0;
  public carouselTimer: number = 0;
  public smallNavigationOptions = false;

  constructor(
    public readonly ui: UiService,
    private readonly router: Router,
    private readonly renderer: Renderer2,
    private readonly cacheService: CacheService,
    private readonly elementRef: ElementRef,
    private readonly cd: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit(): void {
    this.init();
    this.carouselNavigationAdjustment(this.skCarouselTimerBar);
  }

  private init() {
    if (this.interval) {
      clearInterval(this.interval)
    };
    this.cd.detectChanges();
    this.interval = setInterval(() => {
      const bar = this._skCarouselTimerBar?.nativeElement;
      if (!bar) {
        return;
      }
      if (bar.clientWidth <= 0) {
        try {
          this.resetBar(bar);
          this.setCarouselIdx(this.carouselIdx + 1);
        } catch (err) {
          clearInterval(this.interval);
        }
      }
    }, 100);
  }

  public getParentForHighlight(highlight: ISKHighlight) {
    let parent: ISKRootCategory | ISKCategoryData | null = null;
    try {
      const parentId = highlight?.parentId;
      const parentRootId = highlight?.parentRootId;
      if (!parent && parentId) {
        parent = this.cacheService.category.value.find(cc => cc.id === parentId);
      }
      if (!parent && parentRootId) {
        parent = this.cacheService.content.value.find(crc => crc.id === parentRootId);
      }
    } catch (err) {
      if (!environment.production) {
        console.log('[SkCarouselComponent] Error while getting parent of highlight', highlight, err);
      }
    }
    return parent;
  }

  public navigateToHighlight(highlight: ISKHighlight) {
     this.router.navigate(['offers'], {state: {
         select: {
           id: highlight.highlightObject.id,
           type: highlight.type,
           highlight: highlight
         }
       }
     }).then(() => {
     })
  }

  public setCarouselIdx(idx: number) {
    const bar = this._skCarouselTimerBar.nativeElement;
    if (bar) {
      this.resetBar(bar);
    }
    this.carouselIdx = idx < 0 ? this.highlights.length - 1 : idx < this.highlights.length ? idx : 0;
  }

  public carouselNavigationAdjustment(carouselNavigation: ElementRef<HTMLDivElement>) {
    if (carouselNavigation) {
      const containerWidth = carouselNavigation?.nativeElement?.scrollWidth;
      const children = Array.prototype.slice.call(carouselNavigation?.nativeElement?.children, 0);
      let width = 0;
      children.forEach((child: HTMLDivElement) => {
        width += child.scrollWidth;
      });
      if (containerWidth - width <= 50) {
        this.smallNavigationOptions = true;
        this.cd.detectChanges();
      }
    }
  }

  private resetBar(bar: HTMLDivElement) {
    try {
      const copy = bar.cloneNode(true);
      const parent = bar.parentNode;
      parent?.replaceChild(copy, bar);
      const children = (parent?.children?.length > 0) ? Array.from(parent?.children) : [];
      if (children) {
        const copyEl = children.find(c => c.id.includes('bar'));
        this.skCarouselTimerBar = new ElementRef<HTMLDivElement>(copyEl as HTMLDivElement);
      }
    } catch(err) {
      if (!environment.production) {
        console.warn('[SkCarouselComponent] Error while resetting carousel bar: ', err);
      }
      clearInterval(this.interval);
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
