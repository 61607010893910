import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SkLandingComponent } from "./views/sk-landing/sk-landing.component";
import { SkOffersComponent } from "./views/sk-offers/sk-offers.component";
import { SkStaticBookingRequestComponent } from "./views/sk-static-booking-request/sk-static-booking-request.component";
import { SkStaticReferenceComponent } from "./views/sk-static-reference/sk-static-reference.component";
import { SkStaticNewsletterComponent } from "./views/sk-static-newsletter/sk-static-newsletter.component";
import { SkStaticAgencyProfileComponent } from "./views/sk-static-agency-profile/sk-static-agency-profile.component";
import { SkStaticImprintComponent } from "./views/sk-static-imprint/sk-static-imprint.component";
import { SkStaticPrivacyPolicyComponent } from "./views/sk-static-privacy-policy/sk-static-privacy-policy.component";
import { SkStaticTermsOfServiceComponent } from "./views/sk-static-terms-of-service/sk-static-terms-of-service.component";
import { SkStaticPaymentOptionsComponent } from "./views/sk-payment-options/sk-static-payment-options.component";

export const routes: Routes = [
  { path: '', component: SkLandingComponent },
  { path: 'landing', component: SkLandingComponent, data: {endpoint: null} },
  { path: 'agency-profile', component: SkStaticAgencyProfileComponent, data: {endpoint: 'agency-profile?populate=image,logos'} },
  { path: 'offers', component: SkOffersComponent, data: {endpoint: null} },
  { path: 'booking-form', component: SkStaticBookingRequestComponent, data: {endpoint: 'booking-form'} },
  { path: 'reference', component: SkStaticReferenceComponent, data: {endpoint: 'reference'} },
  { path: 'newsletter-registration', component: SkStaticNewsletterComponent, data: {endpoint: 'newsletter-registration'} },
  { path: 'privacy-policy', component: SkStaticPrivacyPolicyComponent, data: {endpoint: 'privacy-policy', notInMenu: true} },
  { path: 'payment-option', component: SkStaticPaymentOptionsComponent, data: {endpoint: 'payment-option', notInMenu: true, notInSitemap: true} },
  { path: 'terms-of-service', component: SkStaticTermsOfServiceComponent, data: {endpoint: 'terms-of-service', notInMenu: true} },
  { path: 'imprint', component: SkStaticImprintComponent, data: {endpoint: 'imprint', notInMenu: true} },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
