<div *ngIf="pageData" class="row m-0 px-0 py-4">

  <div class="sk-footer-item sk-footer-item-1 col-md-4">
    <h5 [innerText]="pageData['payment_options_text']"></h5>
    <div class="pb-4">
      <img (click)="navigate(paymentOptsRoute)" class="payment-img" src="assets/images/payment-icon.png">
    </div>
    <h5 [innerText]="pageData['partner_text']"></h5>
    <div class="row w-100 partner-logos">
      <div class="partner-logo position-relative p-2 col-sm-4 col-4 g-2" *ngFor="let logoImage of logoImages">
        <img [src]="logoImage" [alt]="logoImage.split('/').reverse()[0]">
      </div>
    </div>
  </div>

  <div class="sk-footer-item sk-footer-item-2 col-md-4">
    <h5 [innerText]="pageData['links_text']"></h5>
    <div class="sk-footer-socials">
      <div *ngFor="let socialLink of pageData['links']">
        <div class="sk-footer-socials-item d-flex flex-row justify-content-start align-items-center my-2">
          <sk-svg-icon [cssIconSize]="'20px'" [icon]="socialLink['logo_file']"></sk-svg-icon>
          <div [innerText]="socialLink['name']"></div>
          <a [href]="socialLink['url']" target="_blank"></a>
        </div>
      </div>
    </div>
  </div>

  <div class="sk-footer-item sk-footer-item-3 col-md-4">
    <h5 [innerText]="pageData['sitemap_text']"></h5>
    <div *ngFor="let staticPage of staticPages" class="sk-footer-list-item"
         (click)="navigate(staticPage?.data ? staticPage.data.path : null)"
         [innerText]="staticPage?.data?.attributes ? staticPage.data.attributes.title : null"></div>
  </div>

</div>

