import { Component } from '@angular/core';
import { BaseView } from "../baseView";
import {
  ISKGenericStaticPageAttributes,
  ISKStaticPages,
  StaticPagesService
} from "../../services/static-pages/static-pages.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-sk-static-imprint',
  templateUrl: './sk-static-imprint.component.html',
  styleUrls: ['./sk-static-imprint.component.scss']
})
export class SkStaticImprintComponent extends BaseView {

  private readonly endpoint = this.activatedRoute.snapshot.data['endpoint'];
  public readonly logoImages: string[] = [];
  public pageData: ISKGenericStaticPageAttributes;

  constructor(
    private readonly staticPagesService: StaticPagesService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    super();
    this.subscriptions.push(this.staticPagesService.partnerLogos.subscribe((logoImages: string[]) => {
      logoImages.forEach((logoImage: string) => {
        if (!this.logoImages.includes(logoImage)) {
          this.logoImages.push(logoImage);
        }
      })
    }));
    this.subscriptions.push(this.staticPagesService.staticPageData.subscribe((staticPages: ISKStaticPages) => {
      const pageData = staticPages[this.endpoint];
      if (pageData) {
        this.pageData = pageData.attributes;
        console.log('Imprint pagedata: ', this.pageData);
      }
    }));
  }

}
