import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { MarkdownModule } from 'ngx-markdown';
import { AppComponent } from './app.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/* material Modules */
import { MaterialModule } from "./material.module";

/* SK Directives */
import { NgVarDirective } from './directives/ng-var.directive';
const acvDirectives = [
  NgVarDirective
]

/* SK Modules */
import { ServicesModule } from "./services/services.module";
import { SkComponentsModule } from "./components/sk-components.module";
const skModules = [
  ServicesModule,
  SkComponentsModule
]

/* SK Components */
import { BaseView } from "./views/baseView";
import { SkLandingComponent } from './views/sk-landing/sk-landing.component';
import { SkOffersComponent } from './views/sk-offers/sk-offers.component';
import { SkStaticBookingRequestComponent } from './views/sk-static-booking-request/sk-static-booking-request.component';
import { SkStaticReferenceComponent } from './views/sk-static-reference/sk-static-reference.component';
import { SkStaticNewsletterComponent } from './views/sk-static-newsletter/sk-static-newsletter.component';
import { SkStaticAgencyProfileComponent } from './views/sk-static-agency-profile/sk-static-agency-profile.component';
import { SkStaticImprintComponent } from './views/sk-static-imprint/sk-static-imprint.component';
import { SkStaticPrivacyPolicyComponent } from './views/sk-static-privacy-policy/sk-static-privacy-policy.component';
import { SkStaticTermsOfServiceComponent } from './views/sk-static-terms-of-service/sk-static-terms-of-service.component';
import { SkStaticPaymentOptionsComponent } from './views/sk-payment-options/sk-static-payment-options.component';

const skComponents = [
  BaseView,
  SkLandingComponent,
  SkOffersComponent,
  SkStaticBookingRequestComponent,
  SkStaticReferenceComponent,
  SkStaticNewsletterComponent,
]

@NgModule({
  declarations: [
    AppComponent,
    ...skComponents,
    SkStaticAgencyProfileComponent,
    SkStaticImprintComponent,
    SkStaticPrivacyPolicyComponent,
    SkStaticTermsOfServiceComponent,
    SkStaticPaymentOptionsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NgScrollbarModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    MarkdownModule.forRoot({ loader: HttpClient }),
    MaterialModule,
    ...skModules,
  ],
  exports: [
  ],
  providers: [
    ...acvDirectives,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
