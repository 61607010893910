import { Component, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: 'sk-logo',
  templateUrl: './sk-logo.component.html',
  styleUrls: ['./sk-logo.component.scss']
})
export class SkLogoComponent implements OnInit {

  public readonly isMobile = this.device.isMobile();
  @Input() logoSize: string = '60px';
  @Input() showLogo: boolean = true;
  @Input() showFont: boolean = true;

  constructor(
    private readonly device: DeviceDetectorService
  ) { }

  ngOnInit(): void {
  }

}
