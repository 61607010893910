
<div id="sk-top-bar" #topBarContainer [style.max-width]="'100vw'" class="w-100 d-flex flex-row justify-content-center align-items-center">

  <div class="w-100 d-flex flex-column">

    <!-- Optional DEV tools -->
<!--    <div *ngIf="!env.production" id="dev-tools" class="w-100 d-flex flex-row justify-content-center align-items-center">-->
<!--      <mat-chip-listbox aria-label="DEV">-->
<!--        <mat-chip-option (click)="setLocale(locale)" *ngFor="let locale of locales; let idx = index" [selected]="locale.code === getLocale().code">{{locale.name}}</mat-chip-option>-->
<!--      </mat-chip-listbox>-->
<!--    </div>-->

    <!-- Top Bar for Desktop -->
    <div *ngIf="!isMobile" #topBar id="sk-top-bar-content" [style.max-width]="'100vw'" class="w-100 d-flex flex-row justify-content-center align-items-center">
      <!-- Dynamic logo -->
      <div class="sk-dynamic-logo" [class.exceed]="exceed" [style.height]="topBar.clientHeight + 'px'" [style.width]="topBarOffset <= 0 ? topBarHeight + 'px' : '0px'"></div>
      <!-- Logo on exceed -->
      <div *ngIf="exceed" class="sk-exceed-logo" [style.opacity]="topBarContainer.getBoundingClientRect().top <= 0 ? '1' : '0'"></div>
      <!-- Navigation -->
      <div class="content w-auto d-flex flex-row justify-content-center align-items-center" [class.exceed]="exceed">
        <ng-container *ngFor="let route of routes; let idx = index;">
          <ng-container *ngIf="(route.data && !route.data['notInMenu'])">
            <ng-container *ngVar="getMenuItemTitle(route) as optionTitle">
              <sk-category (click)="navigate(route.path)" [optionTitle]="optionTitle"></sk-category>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <!-- Dynamic menu buttons -->
      <div *ngIf="exceed" class="btn-container d-flex flex-row justify-content-center align-items-center flex-shrink-0 px-1">
        <button  mat-icon-button [matMenuTriggerFor]="menu" color="accent" aria-label="close">
          <mat-icon>menu</mat-icon>
        </button>
        <button mat-stroked-button [matMenuTriggerFor]="localeMenu">{{getTranslation('locale_menu')}}</button>
      </div>
    </div>

    <!-- Top Bar for Mobile -->
    <div *ngIf="isMobile" #topBar id="sk-top-bar-content-mobile" class="w-100 d-flex flex-row justify-content-between align-items-center">
      <!-- Dynamic logo -->
      <div class="sk-dynamic-logo" [style.height]="'50px'" [style.width]="topBarOffset <= 0 ? topBarHeight + 'px' : '0px'"></div>
      <!-- Mobile menu button -->
      <button mat-icon-button [matMenuTriggerFor]="menu" color="accent" aria-label="close">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

  </div>

  <mat-menu #menu="matMenu" [hasBackdrop]="true">
    <ng-container *ngFor="let route of routes; let idx = index;">
      <button mat-menu-item
              [routerLink]="route.path" [queryParams]="{}"
              *ngIf="(route.data && !route.data['notInMenu'] && route.data['title'])"
              [innerText]="route.data['title']"></button>
    </ng-container>
  </mat-menu>
  <mat-menu #localeMenu="matMenu">
    <ng-container *ngIf="locales && locales.length > 0">
      <button (click)="setLocale(locale)" *ngFor="let locale of locales" mat-menu-item>{{locale.name}}</button>
    </ng-container>
  </mat-menu>
</div>

