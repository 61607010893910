import {EventEmitter, Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {DataService} from "../data/data.service";
import {catchError, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private textFields: {[key: string]: string} | undefined;
  private locales: ISKLocale[] = [];
  private _current: ISKLocale;
  private readonly fallback: ISKLocale = {code: 'en', name: 'English'};
  private navLanguage: ISKLocale = navigator?.language ? {code: navigator.language, name: navigator.language} : this.fallback;
  private get current(): ISKLocale {
    return this._current || this.fallback;
  };
  private set current(locale: ISKLocale) {
    if (this._current?.code !== locale?.code) {
      this._current = locale;
      this.$current.next(locale);
    }
  }
  public $current: Subject<ISKLocale> = new Subject<ISKLocale>();

  constructor(
    private readonly dataService: DataService
  ) {
    this.init();
  }

  private init() {
    // If the cookie consent is accepted, get locales from cache
    const cached = !!localStorage.getItem('c') ? JSON.parse(localStorage?.getItem('locales') || '[]') as ISKLocale[] : [];
    if (cached && cached.length > 0) {
      this.locales = cached;
    }
    this.localeRequest().then((locales: ISKLocale[]) => {
      this.locales = locales;
      const initialCurrent = this.getMatching(this.getCurrentFromCache() || this.navLanguage);
      this.setCurrent(initialCurrent, initialCurrent.auto).then();
    })
  }

  public getField(selector: string): string {
    return (this.textFields && this.textFields[selector]) ? this.textFields[selector] : '';
  }

  private getMatching(locale: ISKLocale): ISKLocale {
    const exactMatch = this.locales.find(l => l.code === locale.code);
    const substrMatch = this.locales.find(l => l.code === locale.code.substring(0, 2));
    return exactMatch || substrMatch || this.fallback;
  }

  private async localeRequest(): Promise<ISKLocale[]> {
    return this.dataService.get<ISKLocale[]>(environment.apiUrl + '/i18n/locales').then((locales: ISKLocale[]) => {
      // If the cookie consent is accepted, write locales to cache
      if (!!localStorage.getItem('c')) {
        localStorage.setItem('locales', JSON.stringify(this.locales));
      }
      return locales;
    }).catch((err) => {
      switch (environment.production) {
        case true:
          console.warn('Error while getting locales');
          break;
        case false:
          console.warn('Error while getting locales: ', err);
          break;
      }
      return null;
    });
  }

  private async getGenericTranslation(locale: ISKLocale): Promise<{[key: string]: string}> {
    const response = await this.dataService.get<any>(environment.apiUrl + '/generic-text?locale=' + locale.code);
    return response?.data?.attributes?.text_fields as {[key: string]: string};
  }

  private getCurrentFromCache(): ISKLocale | null {
    // Cet from cache if consent was given
    let current = null;
    if (!!localStorage.getItem('c')) {
      const cachedLocale = localStorage.getItem('locale') || null;
      if (cachedLocale) {
        current = JSON.parse(cachedLocale);
      }
    }
    return current;
  }

  public async setCurrent(locale: ISKLocale, auto = true): Promise<void> {
    locale.auto = auto;
    if (!!localStorage.getItem('c')) {
      localStorage.setItem('locale', JSON.stringify(locale));
    }
    this.textFields = await this.getGenericTranslation(locale);
    this.current = locale;
  }

  public getLocales(): ISKLocale[] {
    return this.locales;
  }

  public async getLocalesFromApi(): Promise<ISKLocale[]> {
    return this.localeRequest().then((locales: ISKLocale[]) => {
      return locales;
    })
  }

  public getLocale(code: string): ISKLocale {
    return this.locales.find(l => l.code === code);
  }

  public getCurrent(): ISKLocale {
    return this.current;
  }
}

export interface ISKLocale {
  code: string;
  name: string;
  auto?: boolean;
}
