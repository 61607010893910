import {Component, Input, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";

@Component({
  template: ''
})
export class BaseView implements OnDestroy {

  @Input() public portrait = false;
  public subscriptions: Subscription[] = [];


  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
