import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  HostListener, OnInit,
} from '@angular/core';
import {DataService} from "../../services/data/data.service";
import {
  ContentService,
  ISKCategoryData,
  ISKHighlight,
  ISKOfferData, ISKOfferDataDescription,
  ISKRootCategory
} from "../../services/content/content.service";
import {UiService} from "../../services/ui/ui.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {CacheService, ISKCachedCategoryData, ISKCachedOfferData} from "../../services/cache/cache.service";
import {ActivatedRoute, Router} from "@angular/router";
import {BaseView} from "../baseView";
import {OffersService} from "../../services/offers/offers.service";
import {UtilsService} from "../../services/utils/utils.service";
import {ISKLocale, LocaleService} from "../../services/locale/locale.service";

@Component({
  selector: 'sk-offers',
  templateUrl: './sk-offers.component.html',
  styleUrls: ['./sk-offers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkOffersComponent extends BaseView implements OnInit {

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    this.topMargin = this.ui.getDynamicTopMargin();
    this.pageHeight = this.ui.getDynamicPageHeight();
  }

  public offersToDisplay: (ISKCachedOfferData | ISKOfferData)[] = [];
  public selectedRootCategory: ISKRootCategory | undefined | null = this.offersService.selectedRootCategory.value; // To avoid service access in template
  public selectedCategory: ISKCategoryData | undefined | null = this.offersService.selectedCategory.value; // To avoid service access in template
  public selectedOffer: ISKOfferData | undefined | null = this.offersService.selectedOffer.value; // To avoid service access in template
  public rootCategories: ISKRootCategory[] = [];
  public readonly cmsUrl = this.dataService.cmsUrl;
  public readonly apiUrl = this.dataService.apiUrl;
  public readonly domain = this.dataService.domain;
  public topMargin = this.ui.getDynamicTopMargin();
  public pageHeight = this.ui.getDynamicPageHeight();
  public categoryOfferCache: ISKCategoryOfferCache = {};
  public host: HTMLElement;
  public pageLoading = false;
  public locale = '';

  constructor(
    public readonly contentService: ContentService,
    public readonly ui: UiService,
    public readonly device: DeviceDetectorService,
    public readonly utils: UtilsService,
    private readonly offersService: OffersService,
    private readonly cacheService: CacheService,
    private readonly dataService: DataService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly localeService: LocaleService,
    private readonly router: Router,
    private readonly cd: ChangeDetectorRef
  ) {
    super();

    // Process initial navigation information
    this.initialNavigation();

    // Set locale
    this.locale = this.localeService.getCurrent().name;

    // Process Query Parameters
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const selectedOfferId = queryParams ? queryParams['id'] : null;

    if (selectedOfferId) {
      this.findSelectedOffer(selectedOfferId).then((selectedOffer: ISKCachedOfferData) => {
        this.setSelectedOffer(selectedOffer);
      });
    }
  }

  ngOnInit(): void {
    // Get all root Categories (needed in template)
    this.subscriptions.push((this.cacheService.content.subscribe((content: ISKRootCategory[]) => {
      this.rootCategories = content;
      this.pageLoading = false;
      this.cd.detectChanges();
    })));

    this.subscriptions.push((this.cacheService.offer.subscribe((offers: ISKCachedOfferData[]) => {
      if (this.selectedOffer) {
        const selectedOffer = (offers.find(o => o.id === this.selectedOffer.id));
        if (selectedOffer) {
          this.setSelectedOffer(selectedOffer);
        } else {
          this.setSelectedOffer(null);
        }
      }
    })));

    // Subscribe to locale change, set page to loading state on locale change
    this.subscriptions.push(this.localeService.$current.subscribe((locale: ISKLocale) => {
      this.locale = locale.name;
      this.pageLoading = true;
      this.rootCategories = null;
      this.cd.detectChanges();
      this.setSelectedCategories(null, null);
      console.log('[SkOffersComponent] Locale change detected. Page state: ', this.pageLoading ? 'LOADING' : 'READY');
    }));

    // Subscription on selected root category change
    this.subscriptions.push(this.offersService.selectedRootCategory.subscribe((selectedRootCategory: ISKRootCategory) => {
      console.log('[SkOffersComponent] Selected Root Category changed: ', selectedRootCategory);
      this.selectedRootCategory = selectedRootCategory;
    }));

    // Subscription on selected category change
    this.subscriptions.push(this.offersService.selectedCategory.subscribe((selectedCategory: ISKCategoryData) => {
      console.log('[SkOffersComponent] Selected Category changed: ', selectedCategory);
      this.offersToDisplay = [];
      this.selectedCategory = selectedCategory;
      const offers: ISKOfferData[] = [];

      // Modify array
      if (selectedCategory) {
        const childOffers = this.offersService.findChildOffers(selectedCategory);
        if (childOffers && childOffers.length > 0) {
          offers.push(...childOffers);
        }
        console.log('[SkOffersComponent] offersToDisplay: ', this.offersToDisplay);
      }

      // Delete expired offers
      const deletedOffers: ISKOfferData[] = [];
      for(let i = 0; i < offers.length; i++) {
        const now = new Date();
        const expirationDate = new Date(offers[i].attributes.expiration_date);
        if (now.getTime() >= expirationDate.getTime()) {
          deletedOffers.push(offers[i]);
          delete offers[i];
        }
      }

      // Console log for dev
      console.log(`[SkOffersComponent] Deleted expired ${deletedOffers.length} offers:`);
      for(let i = 0; i < deletedOffers.length; i++) {
        console.log(`\t${deletedOffers[i].attributes.expiration_date}, ${deletedOffers[i].attributes.name}`);
      }

      // Sort offers
      offers.sort((a: ISKCachedOfferData | ISKOfferData, b: ISKCachedOfferData | ISKOfferData) => {
        const date_a = (new Date(a.attributes.expiration_date)).getTime();
        const date_b = (new Date(b.attributes.expiration_date)).getTime();
        return date_a - date_b;
      })

      this.offersToDisplay = offers.filter(o => o?.id);
      this.cd.detectChanges();
    }));

    // Subscription on selected offer change
    this.subscriptions.push(this.offersService.selectedOffer.subscribe((selectedOffer: ISKOfferData) => {
      this.selectedOffer = selectedOffer;
    }));
  }

  public getTranslation(s: string): string {
    return this.localeService.getField(s);
  }

  private async findSelectedOffer(selectedOfferId: string): Promise<ISKCachedOfferData> {
    console.log(this.cacheService.offer.value);
    console.log(this.contentService.content.value);
    return this.cacheService.offer.value.find(co => co.id === parseInt(selectedOfferId, 10));
  }

  public offerCloseBtnClicked(offer: ISKCachedOfferData): void {
    this.setSelectedOffer(offer);
  }

  private initialNavigation(): void {
    const state = this.router?.getCurrentNavigation()?.extras?.state;
    const select = state ? state['select'] as {id: number, type: string, highlight: ISKHighlight, object: ISKCachedOfferData | ISKCachedCategoryData} : null;
    console.log('initialNavigation: ', select);
    const highlightId = select?.highlight?.highlightObject?.id || select?.object?.id;

    if (highlightId) {
      switch(select['type']) {
        case 'rootCategory':
          const rootCategory = this.cacheService.content.value.find(crc => crc.id === highlightId);
          this.offersService.selectedRootCategory.next(rootCategory);
          this.offersService.selectedCategory.next(null);
          break;
        case 'category':
          const category = this.cacheService.category.value.find(cc => cc.id === highlightId);
          const rootParent = this.cacheService.content.value.find(crc => crc.id === category.parentRootId);
          this.offersService.selectedRootCategory.next(rootParent);
          this.offersService.selectedCategory.next(category);
          break;
        case 'offer':
          const offer = this.cacheService.offer.value.find(co => co.id === highlightId);
          this.setSelectedOffer(offer);
          break;
      }
    }
    if (!select) {
      this.setSelectedOffer(null);
      this.setSelectedCategories(null, null);
    }
  }

  public sendOfferToBookingForm(offer: ISKOfferData, packageId: number) {
    console.log(offer, offer.attributes.description[packageId]);
    const cachedOffer = this.cacheService.offer.value.find(co => co.id === offer.id)
    const parent = this.cacheService.category.value.find(cc => cc.id === cachedOffer?.parentCategoryId);
    this.router.navigate(['booking-form'], {state: {
        offer: {
          offer: cachedOffer,
          packageId,
          parent
        }
      }
    }).then(() => {
    })
  }

  private setSelectedOffer(offer: ISKCachedOfferData | ISKOfferData | undefined | null) {
    console.log('SELECTED OFFER SET ------> ', offer);
    this.router.navigate([], {
      queryParams: {
        id: offer ? offer.id : null
      }
    }).then((result: boolean) => {
      this.offersService.selectedOffer.next(offer ? offer : undefined);
      window.scrollTo(0,0);
    })
  }

  public getLowestPriceForOffer(offer: ISKCachedOfferData | ISKOfferData): number {
    const prices: number[] = [];
    try {
      offer?.attributes?.description?.forEach((pkg: ISKOfferDataDescription) => {
        prices.push(pkg.price);
      })
    } catch (err) {
      prices.push(0)
    }
    return Math.min(...prices);
  }

  public setSelectedCategories(rootCategory?: ISKRootCategory, category?: ISKCategoryData) {
    this.offersService.selectedRootCategory.next(rootCategory);
    this.offersService.selectedCategory.next(category);
  }

  public offerButtonClicked(offer: ISKCachedOfferData | ISKOfferData) {
    this.setSelectedOffer(offer);
  }

  public sideMenuItemClicked() {
  }

}

interface ISKCategoryOfferCache {
  [key: string]: ISKOfferData[];
}
