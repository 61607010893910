import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UiService} from "./services/ui/ui.service";
import {SkTopBarComponent} from "./components/sk-top-bar/sk-top-bar.component";
import {ContentService, ISKRootCategory} from "./services/content/content.service";
import {Subscription} from "rxjs";
import {DataService} from "./services/data/data.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  SkDialogCookieConsentComponent
} from "./components/sk-dialog-cookie-constent/sk-dialog-cookie-consent.component";
import {ISKLocale, LocaleService} from "./services/locale/locale.service";
import {LogService} from "./services/log/log.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy{

  public exceed: boolean = false;
  public locales: ISKLocale[] = [];
  public currentLocale = this.localeService.getCurrent();
  public mainDataSet: ISKRootCategory[] = [];
  private dialogRef: MatDialogRef<SkDialogCookieConsentComponent> | null = null;
  private readonly subscriptions: Subscription[] = [];
  title: string;

  private _logo: HTMLElement | undefined;
  @ViewChild('logo') private set logo(logo: ElementRef) {
    this._logo = logo.nativeElement as HTMLElement;
    this.ui.logoElement = this._logo;
  };

  private _topBar: HTMLElement | undefined;
  @ViewChild('topBar') private set topBar(topBar: SkTopBarComponent) {
    if (topBar && topBar.elementRef) {
      this._topBar = topBar.elementRef.nativeElement as HTMLElement;
      this.ui.topBarElement = this._topBar;
    }
  };

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly logService: LogService,
    private readonly localeService: LocaleService,
    private readonly dialog: MatDialog,
    private readonly contentService: ContentService,
    public readonly ui: UiService,
    public readonly dataService: DataService
  ) {
  }

  ngOnInit(): void {

    this.subscriptions.push(this.contentService.content.subscribe(content => {
      this.mainDataSet = content;
    }));
    this.mainDataSet = this.contentService.content.value;

    this.subscriptions.push(this.localeService.$current.subscribe((locale: ISKLocale) => {
      this.currentLocale = locale;
      this.cd.detectChanges();
    }));

    this.localeService.getLocalesFromApi().then((locales: ISKLocale[]) => {
      this.locales = locales;
    });

    const consent = !!localStorage.getItem('c');
    if (!this.dialogRef && !consent) {
      this.dialogRef = this.dialog.open(SkDialogCookieConsentComponent, {
        disableClose: true,
        position: {bottom: '0'}
      });
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
      })
    }
  }

  public topBarExceed(e: boolean) {
    this.exceed = e;
  }

  public setLocale(locale: ISKLocale): void {
    this.localeService.setCurrent(locale);
  }

  public onOutletLoaded(): void {
  }

  public getTranslation(s: string): string {
    return this.localeService.getField(s);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

}
