import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {DataService} from "../data/data.service";
import {UtilsService} from "../utils/utils.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {CacheService} from "../cache/cache.service";
import {routes} from "../../app-routing.module";
import {Route} from "@angular/router";
import {ISKLocale, LocaleService} from "../locale/locale.service";

@Injectable({
  providedIn: 'root'
})
export class StaticPagesService {

  private readonly staticPages: ISKStaticPages = {};
  private readonly staticContentUri = environment.apiUrl + '/static-sites';
  private readonly prefix = environment.apiUrl + '/';
  private finishedRequests: string[] = [];
  public readonly partnerLogos: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public staticPageData: BehaviorSubject<ISKStaticPages> = new BehaviorSubject<ISKStaticPages>({});
  private readonly subscriptions: Subscription[] = [];

  constructor(
    private readonly localeService: LocaleService,
    private readonly cacheService: CacheService,
    private readonly dataService: DataService,
    private readonly utils: UtilsService
  ) {
    this.subscriptions.push(this.localeService.$current.subscribe((locale: ISKLocale) => {
      if (!environment.production) {
        console.log(`[StaticPagesService] Init called with locale ${locale.code} (${locale.name})`);
      }
      this.init(false);
    }));
  }

  public init(skipCache = false): void {
    console.log('[StaticPagesService] Constructor called');
    console.log('[StaticPagesService] staticContentUri: ', this.staticContentUri);
    if (!skipCache) {
      this.staticPageData.next(this.cacheService.getStaticPagesCache());
      console.log('[StaticPagesService] Got static pages from cache: ', this.staticPageData.value);
    }
    this.loadStaticPages().then();
  }

  public addPartnerLogoImg(url: string): void {
    const arr = JSON.parse(JSON.stringify(this.partnerLogos.value));
    if (!arr.includes(url)) {
      arr.push(url);
    }
    this.partnerLogos.next(arr);
  }

  public getStaticPageData(endpoint: string): ISKStaticPageData | null {
    const data = this.staticPageData.value[endpoint];
    return data ? data : null;
  }

  private async loadStaticPages(): Promise<void> {
    this.finishedRequests = [];
    routes.forEach((route: Route) => {
      if (route?.data && route.data['endpoint']) {
        const startTime = performance.now();
        const endpoint = route.data['endpoint'];
        const path = route.path;
        const localeParam = ((this.prefix + endpoint).includes('?') ? '&' : '?') + 'locale=' + this.localeService.getCurrent().code;
        this.dataService.get<any>(this.prefix + endpoint + localeParam).then((data) => {
          if (data?.data) {
            data.data['endpoint'] = endpoint;
            data.data['path'] = path;
            this.staticPages[endpoint] = data.data;
            this.finishedRequests.push(endpoint);
            const endTime = performance.now();
            //console.log(`[StaticPagesService]: Got ${endpoint} in ${(endTime - startTime).toFixed(2)}ms:`, this.staticPageData.value);
          }
          this.afterLoadStaticPages(this.finishedRequests);
        })
      }
    })
  }

  private afterLoadStaticPages(finishedRequests: string[]) {
    if (finishedRequests.length === routes.filter(r => r.data && r.data['endpoint']).length) {
      console.log(`[StaticPagesService]: Got static pages from API (${this.utils.formatBytes((new Blob([JSON.stringify(this.staticPages)])).size)}):`, this.staticPageData.value);
      this.staticPageData.next(this.staticPages);
      this.cacheService.staticPagesCacheBuilder(this.staticPages);
    }
  }
}

export interface ISKStaticPages {
  [key: string]: ISKStaticPageData;
}

export interface ISKStaticPageData {
  id: number,
  attributes: ISKGenericStaticPageAttributes,
  endpoint: string;
  path: string;
}

export interface ISKGenericStaticPageAttributes {
  title: string,
  createdAt: string,
  updatedAt: string,
  locale: string,
  [key: string]: any;
}
