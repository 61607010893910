<div *ngIf="currentLocale" class="w-100 d-flex flex-column min-vh-100">
  <div #logo [style.max-width]="ui.pageMaxWidth" id="sk-logo-container" class="w-100 d-flex flex-row justify-content-center align-items-center p-1">
    <sk-logo [routerLink]="'/landing'"></sk-logo>
    <div *ngIf="!exceed" class="btn-container d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
      <button mat-stroked-button [matMenuTriggerFor]="localeMenu">{{getTranslation('locale_menu')}}</button>
    </div>
  </div>
  <ng-container *ngIf="!dataService.getErrorDialog()">
    <ng-container *ngIf="mainDataSet && mainDataSet.length > 0">
      <sk-top-bar ($exceed)="topBarExceed($event)" #topBar class="sticky-top d-flex">
      </sk-top-bar>
      <div class="flex-grow-1" [style.margin-top]="topBar.height">
        <router-outlet (activate)="onOutletLoaded()"></router-outlet>
      </div>
    </ng-container>
    <sk-footer></sk-footer>
  </ng-container>
</div>

<mat-menu #localeMenu="matMenu">
  <ng-container *ngIf="locales && locales.length > 0">
    <button (click)="setLocale(locale)" *ngFor="let locale of locales" mat-menu-item>{{locale.name}}</button>
  </ng-container>
</mat-menu>
