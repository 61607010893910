<div class="sk-static-page-container">

  <h1 [innerText]="pageData.title"></h1>

  <p class="sk-p-head" [innerText]="pageData['text']"></p>

  <div class="sk-reference-clients w-100 d-flex flex-row flex-wrap justify-content-evenly">
    <div class="sk-reference-client" *ngFor="let client of pageData['customers']" [innerText]="client">
    </div>
  </div>

</div>
