import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'sk-offer-option',
  templateUrl: './sk-offer-option.component.html',
  styleUrls: ['./sk-offer-option.component.scss']
})
export class SkOfferOptionComponent implements OnInit {

  private _textContainer: ElementRef | undefined;
  @ViewChild('textContainer') set textContainer(el: ElementRef) {
    this._textContainer = el;
  };

  @Input() offerTitle: string = 'Angebot';
  @Input() offerMarkdown: string = '';
  @Input() text: string = '';
  @Input() price: number | string = 0;
  @Input() priceInfo: string = '';
  @Input() hideDescription: boolean = false;
  @Input() color: 'red' | 'green' | 'blue' = 'blue';

  constructor() { }

  ngOnInit(): void {
    ////console.log(this.offerMarkdown);
  }

}

export interface ISKOfferOption {
  offerTitle: string;
  text: string;
  price: number;
}
