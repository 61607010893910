export const environment = {
  production: true,
  baseHref: '',
  domain: 'https://heuristic-williams.212-227-165-9.plesk.page',
  cmsUrl: 'https://cms.heuristic-williams.212-227-165-9.plesk.page',
  apiUrl: 'https://cms.heuristic-williams.212-227-165-9.plesk.page/api',
  placeholderImage: './assets/images/intro-placeholder.jpg',
  solidColorImage: './assets/images/blackPixel.jpg',
  mailchimpNewsletterUrl: 'https://sportkultour.us19.list-manage.com/subscribe/post?u=18c5b3b60fdca49b13316fea5&amp;id=7d702e2d15&amp;v_id=2716&amp;f_id=00ff9fe3f0'
};
