import { Component, ElementRef, ViewChild } from '@angular/core';
import { ISKGenericStaticPageAttributes, StaticPagesService } from "../../services/static-pages/static-pages.service";
import { BaseView } from "../baseView";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-sk-newsletter',
  templateUrl: './sk-static-newsletter.component.html',
  styleUrls: ['./sk-static-newsletter.component.scss']
})
export class SkStaticNewsletterComponent extends BaseView {

  @ViewChild('markdown') set markdown(markdown: ElementRef) {
    if (markdown) {
      const markdownEl = markdown.nativeElement as HTMLElement;
      const links = [].slice.call(markdownEl.getElementsByTagName('a'));
      links.forEach((link: HTMLLinkElement) => {
        link.setAttribute('target', '_blank');
      })
    }
  }

  public buttonDisabled = true;
  public pageData: ISKGenericStaticPageAttributes;
  private readonly endpoint = this.activatedRoute.snapshot.data['endpoint'];

  constructor(
    private staticPagesService: StaticPagesService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    super();
    this.subscriptions.push(staticPagesService.staticPageData.subscribe(() => {
      this.pageData = staticPagesService.getStaticPageData(this.endpoint).attributes;
      //console.log('[SkStaticNewsletterComponent]', this.pageData);
    }))
  }

  public onFormInput($event: Event, mailInput: HTMLInputElement, checkboxInput: HTMLInputElement) {
    this.buttonDisabled = !((mailInput?.value && mailInput?.value?.length > 4) && (checkboxInput.checked))
  }

}
