import {EventEmitter, Injectable, Output} from '@angular/core';
import {DeviceDetectorService, DeviceInfo} from 'ngx-device-detector';
import {ISKCachedCategoryData} from "../cache/cache.service";
import {ISKCategoryData, ISKOfferData} from "../content/content.service";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  public readonly eMailValidationPattern = new RegExp("\A(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)\Z");

  constructor(
    private deviceDetectorService: DeviceDetectorService
  ) { }

  @Output() externalLinkDialogue: EventEmitter<string> = new EventEmitter<string>();

  deepCopy(o: object) {
    return JSON.parse(JSON.stringify(o));
  }

  openLink(url: string) {
    window.open(url);
  }

  getObjKeys(o: any) {
    return Object.keys(o);
  }

  getPriceDisplay(p: number | string, currency: string = '€'): string {
    let price: number;
    if (typeof(p).toString().toLowerCase() === 'string') {
      price = parseInt(p + '', 10);
    } else {
      price = p as number;
    }
    return price.toFixed(2).replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + '€';
  }

  stringToInt(v: string, r = 10): number {
    return Number.parseInt(v, r);
  }

  toLocaleDateStringZero(d: Date, fullYear = true) {
    let lds = d.toLocaleDateString();
    const day = lds.split('.')[0].length === 1 ? '0' + lds.split('.')[0] : lds.split('.')[0];
    const month = lds.split('.')[1].length === 1 ? '0' + lds.split('.')[1] : lds.split('.')[1];
    const year = fullYear ? d.getFullYear() : d.getFullYear().toString().slice(-2);
    return day + '.' + month + '.' + year;
  }

  dateTsToDateString(dateTs: string | number): string {
    dateTs = dateTs + '';
    const d = dateTs.substring(6, 8);
    const m = dateTs.substring(4, 6);
    const y = dateTs.substring(0, 4);
    return d + '.' + m + '.' + y;
  }

  dateTsToDate(dateTs: number | string): Date {
    dateTs = dateTs + '';
    const d = parseInt(dateTs.substring(6, 8), 10);
    const m = parseInt(dateTs.substring(4, 6), 10);
    const y = parseInt(dateTs.substring(0, 4), 10);
    return new Date(y, m - 1, d);
  }

  dateToDateTs(date: Date = new Date()): number {
    return parseInt(this.pad(date.getFullYear(), 4) + '' + this.pad(date.getMonth() + 1) + '' + this.pad(date.getDate()), 10);
  }

  isToday(dateTs: number | string): boolean {
    return this.dateToDateTs() === dateTs;
  }

  pad(s: string | number, l: number = 2, c: string | number = 0): string {
    s = s + '';
    while (s.length < l) {
      s = c + '' + s;
    }
    return s;
  }

  dateStringToDateTs(dateSting: string) {
    const [d, m, y] = dateSting.split('.');
    return parseInt(y + m + d, 10);
  }

  setContainerWidth(device: DeviceInfo): string {
    const portraitMode = device.orientation === 'portrait';
    switch (device.deviceType.toLocaleLowerCase()) {
      case 'mobile':
        return portraitMode ? '100%' : '50%';
        break;
      case 'tablet':
        return portraitMode ? '95%' : '60%';
        break;
      case 'desktop':
        return '1000px';
        break;
      default:
        return '100%'
    }
  }

  redirect($event: MouseEvent , force = true) {
    const href =($event.target as HTMLElement).getAttribute('href') as string;
    $event.preventDefault();
    if (force || localStorage.getItem('preventDialogue')) {
      window.open(href, '_blank');
    } else {
      this.externalLinkDialogue.emit(href);
    }
  }

  extractDomainFromUrl(url: string): string {
    url = url.replace((url.startsWith('https') ? 'https' : 'http') + '://', '');
    url = url.replace('www.', '');
    const segments = url.split('/');
    return url;
  }

  uiGetMaxContainerWidth(): string | null {
    /* Get device and set orientation */
    const device = this.deviceDetectorService.getDeviceInfo();
    if (device) {
      return this.setContainerWidth(device);
    } else {
      console.error('Device detection failure');
      return null;
    }
  }

  uiGetOrientation(): string | null {
    /* Get device and set orientation */
    const device = this.deviceDetectorService.getDeviceInfo();
    if (device) {
      return device.orientation;
    } else {
      console.error('Device detection failure');
      return null;
    }
  }

  formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }
}
