<ng-container *ngIf="!attributes">
  <div class="w-100 d-flex flex-row justify-content-center align-items-center py-3 cookie-spinner-fade-in" [style.min-height]="'250px'">
    <mat-spinner></mat-spinner>
  </div>
</ng-container>

<ng-container *ngIf="attributes">
  <h3 class="fw-bold" [innerText]="attributes['title']"></h3>
  <markdown [data]="attributes['text']"></markdown>
  <markdown [data]="attributes['text_details']"></markdown>

  <div class="w-100 d-flex flex-row justify-content-start align-items-center py-3">
    <mat-slide-toggle
      class="example-margin mx-2"
      [color]="'accent'"
      [checked]="essential"
      [(ngModel)]="essential">
    </mat-slide-toggle>
    <div class="fw-bold mx-2" [innerText]="attributes['text_essential']"></div>
  </div>

  <div class="w-100 d-flex flex-row justify-content-end">
    <button class="mx-2" mat-stroked-button (click)="save()" color="accent" [innerText]="attributes['text_save']" [disabled]="requestOngoing">
    </button>
    <button class="mx-2" mat-flat-button (click)="acceptAll()" color="primary" [innerText]="attributes['text_accept']" [disabled]="requestOngoing">
    </button>
  </div>
</ng-container>

