import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {SkDialogApiErrorComponent} from "../../components/sk-dialog-api-error/sk-dialog-api-error.component";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public readonly requestOngoing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly cmsUrl = environment.cmsUrl;
  public readonly apiUrl = environment.apiUrl;
  public readonly domain = environment.domain;
  private dialogRef: MatDialogRef<SkDialogApiErrorComponent> | null = null;
  private readonly logoImagePrefix = './assets/images/logos/';
  public readonly logoImages: string[] = [
    this.logoImagePrefix + 'drv.png',
    this.logoImagePrefix + 'eventim.png',
    this.logoImagePrefix + 'rv.png',
  ];

  constructor(
    private readonly dialog: MatDialog,
    private readonly httpClient: HttpClient
  ) { }

  public getErrorDialog(): MatDialogRef<SkDialogApiErrorComponent> | null {
    return this.dialogRef;
  }

  async get<T>(url: string, options: IHttpOptions = {}): Promise<T|undefined> {
    this.requestOngoing.next(true);
    return lastValueFrom(this.httpClient.get<T|undefined>(url, options)).then(data => {
      return data;
    }).catch((err) => {
      this.onApiError(url);
      return null;
    }).finally(() => {
      this.requestOngoing.next(false);
    })
  }

  async getAsset<T>(url: string, options: IHttpOptions = {}): Promise<T|undefined> {
    this.requestOngoing.next(true);
    const uri = (environment.baseHref + '/assets/' + url).replace(/\/\//g, '/');
    return lastValueFrom(this.httpClient.get<T|undefined>(uri, options)).then(data => {
      return data;
    }).catch((err) => {
      this.onApiError(url);
      return null;
    }).finally(() => {
      this.requestOngoing.next(false);
    })
  }

  async post<T>(url: string, payload: any, options: IHttpOptions = {}): Promise<T|undefined> {
    this.requestOngoing.next(true);
    return lastValueFrom(this.httpClient.post<T|undefined>(url, payload, options)).then(data => {
      return data;
    }).catch((err) => {
      this.onApiError(url);
      return null;
    }).finally(() => {
      this.requestOngoing.next(false);
    })
  }

  private onApiError(url: string): void {
    this.requestOngoing.next(false);
    if (!this.dialogRef && url.includes(environment.apiUrl)) {
      console.error('API_ERROR: Contact administration');
      this.dialogRef = this.dialog.open(SkDialogApiErrorComponent, {
        disableClose: true
      });
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
      })
    }
  }

}

interface IHttpOptions {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;

  [key: string]: any;
}
