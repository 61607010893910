import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SkSvgIconComponent } from './sk-svg-icon/sk-svg-icon.component';
import { SkLogoComponent } from './sk-logo/sk-logo.component';
import { SkCategoryComponent } from './sk-category/sk-category.component';
import { SkTopBarComponent } from './sk-top-bar/sk-top-bar.component';
import { SkOfferCardComponent } from './sk-offer-card/sk-offer-card.component';
import { SkSideMenuComponent } from './sk-side-menu/sk-side-menu.component';
import { SkViewContainerContentComponent } from './sk-view-container-content/sk-view-container-content.component';
import { SkViewContainerSideMenuComponent } from './sk-view-container-side-menu/sk-view-container-side-menu.component';
import { SkButtonComponent } from './sk-button/sk-button.component';
import { SkGenericCardComponent } from './sk-generic-card/sk-generic-card.component';
import { SkOfferOptionComponent } from './sk-offer-card/sk-offer-option/sk-offer-option.component';
import { MarkdownModule } from "ngx-markdown";
import { SkCarouselComponent } from './sk-carousel/sk-carousel.component';
import { SkSearchComponent } from './sk-search/sk-search.component';
import { SkSideMenuSectionComponent } from './sk-side-menu/sk-side-menu-section/sk-side-menu-section.component';
import { SkFooterComponent } from './sk-footer/sk-footer.component';

/* Material Modules */
import { MaterialModule } from "../material.module";

/* ACV Directives */
import { NgVarDirective } from '../directives/ng-var.directive';
import { SkDialogApiErrorComponent } from './sk-dialog-api-error/sk-dialog-api-error.component';
import { SkDialogCookieConsentComponent } from './sk-dialog-cookie-constent/sk-dialog-cookie-consent.component';

const acvDirectives = [
  NgVarDirective
]

@NgModule({
  declarations: [
    ...acvDirectives,
    SkSvgIconComponent,
    SkLogoComponent,
    SkCategoryComponent,
    SkTopBarComponent,
    SkOfferCardComponent,
    SkSideMenuComponent,
    SkViewContainerContentComponent,
    SkViewContainerSideMenuComponent,
    SkGenericCardComponent,
    SkOfferOptionComponent,
    SkCarouselComponent,
    SkSearchComponent,
    SkSideMenuSectionComponent,
    SkButtonComponent,
    SkFooterComponent,
    SkDialogApiErrorComponent,
    SkDialogCookieConsentComponent,
  ],
    imports: [
      CommonModule,
      AppRoutingModule,
      FormsModule,
      ReactiveFormsModule,
      MarkdownModule,
      NgScrollbarModule,
      BrowserAnimationsModule,
      MaterialModule
    ],
  providers: [
  ],
  exports: [
        NgVarDirective,
        SkSvgIconComponent,
        SkLogoComponent,
        SkCategoryComponent,
        SkTopBarComponent,
        SkOfferCardComponent,
        SkSideMenuComponent,
        SkViewContainerContentComponent,
        SkViewContainerSideMenuComponent,
        SkGenericCardComponent,
        SkOfferOptionComponent,
        SkCarouselComponent,
        SkSearchComponent,
        SkButtonComponent,
        SkFooterComponent,
    ]
})
export class SkComponentsModule { }
