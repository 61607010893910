import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataService } from "./data/data.service";
import { UtilsService } from "./utils/utils.service";
import { UiService } from "./ui/ui.service";
import { ContentService } from "./content/content.service";
import { OffersService } from "./offers/offers.service";
import { StaticPagesService } from "./static-pages/static-pages.service";
import { LocaleService } from "./locale/locale.service";

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    DataService,
    UtilsService,
    UiService,
    ContentService,
    OffersService,
    StaticPagesService,
    LocaleService,
  ]
})
export class ServicesModule {

}
