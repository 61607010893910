<div class="w-100 d-flex flex-column">
  <mat-progress-bar *ngIf="!rootCategories" [style.width]="'100vw'" mode="indeterminate"></mat-progress-bar>
  <div class="w-100 d-flex flex-row mx-auto transition-opacity" [style.max-width]="ui.pageMaxWidth" [style.opacity]="!rootCategories ? '0' : '1'">

    <!-- Side-Menu -->
    <sk-view-container-side-menu *ngIf="!device.isMobile()" class="flex-shrink-0">
      <div class="sk-side-menu-carrier">
        <sk-side-menu (onItemClicked)="sideMenuItemClicked()"></sk-side-menu>
      </div>
    </sk-view-container-side-menu>

    <!-- Selected Nothing -->
    <sk-view-container-content class="w-100" [style.top]="topMargin" [detachChangeDetection]="true" *ngIf="!selectedOffer && !selectedRootCategory && !selectedCategory">
      <div class="row">
        <ng-container *ngFor="let rootCategory of rootCategories">
          <div class="col-sm-12 col-md-6 col-lg-4 g-3">
            <sk-generic-card (click)="setSelectedCategories(rootCategory, null)"
                             class="w-100 hover-transform"
                             [leftBorder]="true"  [cardTitle]="rootCategory.attributes.name" [imageItem]="rootCategory">
            </sk-generic-card>
          </div>
        </ng-container>
      </div>
    </sk-view-container-content>

    <!-- Selected Root Category -->
    <sk-view-container-content [style.top]="topMargin" [detachChangeDetection]="true" *ngIf="!selectedOffer && selectedRootCategory && !selectedCategory">
      <div class="d-flex flex-row flex-wrap justify-content-start align-items-center">

        <!-- Title and close btn -->
        <div class="title-bar d-flex w-100 justify-content-between align-items-center">
          <h3 class="user-select-none" [innerText]="selectedRootCategory.attributes.name"></h3>
          <button (click)="setSelectedCategories(null, null)" mat-icon-button color="accent" aria-label="close">
            <mat-icon>close</mat-icon>
          </button>
        </div>

        <!-- Cards -->
        <div class="row">
          <div *ngFor="let category of selectedRootCategory?.attributes?.child_categories?.data" class="col-sm-12 col-md-6 col-lg-4 g-3">
            <sk-generic-card (click)="setSelectedCategories(selectedRootCategory, category)"
                             class="w-100 hover-transform"
                             [leftBorder]="true" [cardTitle]="category.attributes.name" [imageItem]="category">
            </sk-generic-card>
          </div>
        </div>
      </div>
    </sk-view-container-content>

    <!-- Selected Offer -->
    <sk-view-container-content [style.top]="topMargin" [detachChangeDetection]="true" *ngIf="selectedOffer">
      <sk-generic-card [leftBorder]="true" [cardTitle]="selectedOffer.attributes.name" [hideImage]="true">
        <div class="px-1 mb-2" [style.margin-top]="'-10px'" [innerText]="selectedOffer.attributes.date"></div>
        <sk-svg-icon (click)="offerCloseBtnClicked(null)" [icon]="'close'"></sk-svg-icon>
        <div class="p-1" *ngFor="let desc of selectedOffer.attributes.description; index as idx; last as isLast">
          <h4 class="mb-2 fw-bold" [innerText]="desc.package_title"></h4>
          <markdown [data]="desc.package"></markdown>
          <button class="w-100" mat-flat-button (click)="sendOfferToBookingForm(selectedOffer, idx)" color="primary">
            <div *ngIf="desc?.price && desc.display_price" class="price" [innerText]="getTranslation('offers_buy_now') + ' ' + utils.getPriceDisplay(desc['price'])"></div>
            <div *ngIf="!desc?.price || !desc.display_price" class="price" [innerText]="desc['price_alternative_text']"></div>
          </button>
          <hr *ngIf="!isLast">
        </div>
      </sk-generic-card>
    </sk-view-container-content>

    <!-- Selected Category -->
    <sk-view-container-content *ngIf="selectedCategory && !selectedOffer" [style.top]="topMargin" [detachChangeDetection]="true">

      <!-- Title and close btn -->
      <div class="title-bar d-flex w-100 justify-content-between align-items-center">
        <h3 class="user-select-none" [innerText]="selectedCategory.attributes.name"></h3>
        <button (click)="setSelectedCategories(selectedRootCategory, null)" mat-icon-button color="accent" aria-label="close">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <!-- Cards -->
      <div *ngFor="let offer of offersToDisplay" class="d-block user-select-none">
        <sk-generic-card [leftBorder]="true" [cardTitle]="offer.attributes.name" [hideImage]="true">

          <div class="px-1 mb-2" [style.margin-top]="'-10px'" [innerText]="offer.attributes.date"></div>

          <div class="p-1 d-flex flex-column" *ngVar="offer.attributes.description.length as offerCnt">

            <button mat-flat-button (click)="offerButtonClicked(offer)" color="primary">
              <ng-container *ngVar="getLowestPriceForOffer(offer) as lowestPrice">
                <span *ngIf="lowestPrice > 0 && offerCnt > 1" class="px-1" [style.font-weight]="'700'">{{offerCnt + ' ' + getTranslation('offers_starting_at') + ' ' + utils.getPriceDisplay(lowestPrice)}}</span>
                <span *ngIf="lowestPrice > 0 && offerCnt === 1" class="px-1" [style.font-weight]="'700'">{{getTranslation('offers_singular') + ': ' + utils.getPriceDisplay(lowestPrice)}}</span>
                <span *ngIf="lowestPrice <= 0" class="px-1" [style.font-weight]="'700'">{{offerCnt + ' ' + (offerCnt > 1 ? getTranslation('offers_plural') : getTranslation('offers_singular'))}}</span>
              </ng-container>
            </button>

          </div>
        </sk-generic-card>
      </div>

      <!-- No offers to show -->
      <div *ngIf="!offersToDisplay || offersToDisplay?.length < 1" [innerText]="getTranslation('offers_no_offers')"></div>

    </sk-view-container-content>

  </div>
</div>

<ng-template #noOffers>
  <div class="user-select-none">
    <div class="p-2">
      <sk-generic-card [leftBorder]="false" [cardTitle]="getTranslation('offers_no_offers')" [hideImage]="true">
      </sk-generic-card>
    </div>
  </div>
  <div class="d-flex flex-row flex-wrap justify-content-start align-items-center user-select-none">
    <div *ngFor="let category of selectedRootCategory?.attributes?.child_categories?.data" class="d-block p-2 w-33">
      <sk-generic-card (click)="setSelectedCategories(selectedRootCategory, category)"
                       class="w-100 hover-transform"
                       [leftBorder]="true" [cardTitle]="category.attributes.name" [imageItem]="category">
      </sk-generic-card>
    </div>
  </div>
</ng-template>
