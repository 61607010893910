<div class="sk-offer-option-container d-flex flex-column justify-content-between"
     [class.red]="color === 'red'"
     [class.green]="color === 'green'"
     [class.blue]="color === 'blue'">
<!--  <div class="sk-offer-option-title">{{offerTitle}}</div>-->

  <markdown *ngIf="!hideDescription" class="flex-grow-1" [data]="offerMarkdown"></markdown>

<!--  <div #textContainer class="sk-offer-option-text-container">-->
<!--    <div class="sk-offer-option-price-info">{{priceInfo}}</div>-->
<!--    <div class="sk-offer-option-text" [innerText]="text"></div>-->
<!--  </div>-->
  <div *ngIf="price || priceInfo" class="sk-offer-option-price">
    <div *ngIf="priceInfo" class="price-info">{{priceInfo}}</div>
    <div *ngIf="price > 0" class="price">{{price}}€</div>
  </div>
</div>
