import { Injectable } from '@angular/core';
import { DeviceDetectorService } from "ngx-device-detector";
import { MatSnackBarConfig } from "@angular/material/snack-bar";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UiService {

  // Snackbar
  public readonly snackBarPanelClass = 'sk-snackbar';
  public readonly snackBarConfig: MatSnackBarConfig = {
    duration: 5000,
    panelClass: this.snackBarPanelClass
  }

  // Elements from AppComponent
  public logoElement: HTMLElement | undefined;
  public topBarElement: HTMLElement | undefined;

  public readonly pageMaxWidth = '1920px';
  public topBarHeight: number | undefined;

  private _height: number = window.document.documentElement.clientHeight;
  public get height(): number {
    return this._height;
  }

  private _width: number = window.document.documentElement.clientWidth;
  public get width(): number {
    return this._width;
  }

  private _orientation: string = this.deviceDetectorService.orientation;
  public get orientation(): string {
    return this._orientation;
  }

  constructor(
    private readonly deviceDetectorService: DeviceDetectorService
  ) {
    this.createHostListener();
  }

  private createHostListener() {
    window.onresize = (event: UIEvent) => {
      const documentElement = (event.target as Window).document.documentElement;
      this._height = documentElement.clientHeight;
      this._width = documentElement.clientWidth;
      this._orientation = documentElement.clientWidth > documentElement.clientHeight ? 'landscape' : 'portrait';
    }
  }

  public getDynamicTopMargin(): string {
    const topBarElement = this.topBarElement;
    if (topBarElement) {
      const topRect = topBarElement.getBoundingClientRect();
      const offset = topRect.top + topRect.height;
      return (offset >= topRect.height ? offset : topRect.height) + 'px';
    } else {
      return '0px';
    }
  }

  public getDynamicPageHeight(): string {
    const topBarElement = this.topBarElement;
    if (topBarElement) {
      const topRect = topBarElement.getBoundingClientRect();
      const offset = topRect.top + topRect.height;
      return 'calc(100vh - ' + (offset >= topRect.height ? offset : topRect.height) + 'px)';
    } else {
      return '100vh';
    }
  }

}
