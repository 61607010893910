import { Component } from '@angular/core';
import {
  ISKGenericStaticPageAttributes, ISKStaticPages,
  StaticPagesService
} from "../../services/static-pages/static-pages.service";
import { BaseView } from "../baseView";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "../../services/data/data.service";
import { ISKCategoryImageData } from "../../services/content/content.service";
import { environment } from "../../../environments/environment";

@Component({
  selector: 'sk-static-agency-profile',
  templateUrl: './sk-static-agency-profile.component.html',
  styleUrls: ['./sk-static-agency-profile.component.scss']
})
export class SkStaticAgencyProfileComponent extends BaseView {

  private readonly endpoint = this.activatedRoute.snapshot.data['endpoint'];
  public readonly logoImages: string[] = [];
  public contacts: ISKContact[] = [];
  public pageData: ISKGenericStaticPageAttributes;
  public image: string;

  constructor(
    private readonly staticPagesService: StaticPagesService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dataService: DataService
  ) {
    super();

    this.subscriptions.push(this.staticPagesService.partnerLogos.subscribe((logos: string[]) => {
      logos.forEach((logo: string) => {
        if(!this.logoImages.includes(logo)) {
          this.logoImages.push(logo);
        }
      })
    }));

    this.subscriptions.push(this.staticPagesService.staticPageData.subscribe((staticPages: ISKStaticPages) => {
      const pageData = staticPages[this.endpoint];
      this.image = environment.cmsUrl + (pageData.attributes['image'].data as ISKCategoryImageData).attributes.url;
      if (pageData) {
        this.pageData = pageData.attributes;
        this.contacts = this.pageData['contacts'] as ISKContact[];
      }
    }));
  }

}

interface ISKContact {
  fax: string;
  mail: string;
  name: string;
  phone: string;
  title: string;
}
