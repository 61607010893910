import {Component, ElementRef, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {ISKOfferOption} from "./sk-offer-option/sk-offer-option.component";
import {DeviceDetectorService} from "ngx-device-detector";
import {UiService} from "../../services/ui/ui.service";
import {ISKOfferData, ISKOfferDataDescription} from "../../services/content/content.service";

@Component({
  selector: 'sk-offer-card',
  templateUrl: './sk-offer-card.component.html',
  styleUrls: ['./sk-offer-card.component.scss']
})
export class SkOfferCardComponent implements OnInit {

  @Input() public leftBorder = true;
  @Input() public titleBorder = false;
  @Input() public cardBorder = true;
  @Input() public cardTitle: string = '';
  @Input() public cardAnnotation: string = '';
  @Input() public hideDescription: boolean = false;
  @Input() public imageUrl: string | undefined;
  @Input() public fullWidth: boolean = true;
  @Input() public textAlign: 'center' | 'justify' | 'left' | 'right' = 'left';
  @Input() public offer: ISKOfferData | undefined;
  @Input() public offerOptions: ISKOfferDataDescription[] | undefined;

  constructor(
    public readonly ui: UiService
  ) {
  }

  ngOnInit(): void {
  }

}
