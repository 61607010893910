import { Component } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import {
  StaticPagesService,
  ISKStaticPages, ISKGenericStaticPageAttributes
} from "../../services/static-pages/static-pages.service";
import {BaseView} from "../baseView";

@Component({
  selector: 'app-sk-static-reference',
  templateUrl: './sk-static-reference.component.html',
  styleUrls: ['./sk-static-reference.component.scss']
})
export class SkStaticReferenceComponent extends BaseView {

  private readonly endpoint = this.activatedRoute.snapshot.data['endpoint'];
  public pageData: ISKGenericStaticPageAttributes;

  constructor(
    private readonly staticPagesService: StaticPagesService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    super();
    this.subscriptions.push(this.staticPagesService.staticPageData.subscribe((staticPages: ISKStaticPages) => {
      const pageData = staticPages[this.endpoint];
      if (pageData) {
        this.pageData = pageData.attributes;
        //console.log(`[SkStaticReferenceComponent] Page data:`, this.pageData);
      }
    }));
  }

}
