import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  ContentService,
  ISKCategoryData,
  ISKOfferData, ISKOfferDataDescription,
  ISKRootCategory
} from "../../services/content/content.service";
import {BehaviorSubject, Subscription} from "rxjs";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  CacheService,
  ISKCachedCategoryData,
  ISKCachedOfferData,
} from "../../services/cache/cache.service";
import {UtilsService} from "../../services/utils/utils.service";
import {UiService} from "../../services/ui/ui.service";
import {Router} from "@angular/router";

@Component({
  selector: 'sk-search',
  templateUrl: './sk-search.component.html',
  styleUrls: ['./sk-search.component.scss']
})
export class SkSearchComponent implements OnInit, OnDestroy {

  public hideContainer = false;
  public results: ISKSearchResult[] = [];
  public resultsCache: {[key: string]: ISKSearchResult[]} = {};
  private prevSearchValue: string;
  public searchValue: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly contentService: ContentService,
    private readonly cacheService: CacheService,
    private readonly utils: UtilsService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.searchValue.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        if ((!value || value.length <= 0)) {
          this.results = [];
        } else {
            this.results = [];
            const results = this.getResults(value);
          if (!results || results.length === 0) {
            this.results = [];
          } else {
            this.results.push(...results);
          }
        }
      });
  }

  public setHideContainer(hide: boolean): void {
    this.hideContainer = hide;
  }

  public getLowestPrice(searchResult: ISKSearchResult): string {
    const offer = searchResult.object as ISKCachedOfferData;
    const prices: number[] = [];
    offer.attributes.description.forEach((desc: ISKOfferDataDescription) => {
      prices.push(desc.price);
    });
    return this.utils.getPriceDisplay(Math.min(...prices));
  }

  public navigate(i: ISKSearchResult) {
    switch (i.type) {
      case "offer":
        this.router.navigate(['offers'], {state: {
            select: {
              'id': i.objectId,
              'type': 'offer',
              'object': i.object,
            }
          }
        }).then(() => {
        })
        break;
      case "category":
        this.router.navigate(['offers'], {state: {
            select: {
              'id': i.objectId,
              'type': 'category',
              'object': i.object,
            }
          }
        }).then(() => {
        })
        break;
    }
  }

  public setSearchValue($event: Event, searchVal: string) {
    this.prevSearchValue = (' ' + this.searchValue.value).slice(1).toLowerCase().replace(/^a-zA-Z0-9 ]/g, '');
    this.searchValue.next((searchVal && searchVal.length > 0) ? searchVal.toLowerCase().replace(/^a-zA-Z0-9 ]/g, '') : null);
  }

  public getParent(i: ISKRootCategory | ISKCachedCategoryData) {
    if ('parentCategoryId' in i) {
      const parent = this.cacheService.category.value.find(cc => cc.id === i.parentCategoryId);
      return parent ? parent : this.cacheService.content.value.find(crc => crc.id === i.parentRootId);
    } else {
      return null;
    }
  }

  public getResults(searchVal: string): ISKSearchResult[] {
    searchVal = searchVal.replace(/[^A-Z0-9]/ig, '');
    const resultArr: ISKSearchResult[] = [];

    if (this.resultsCache[searchVal]) {
      resultArr.push(...this.resultsCache[searchVal]);
    }
    else {
      const start = performance.now();
      // Search for offers
      const offers: ISKCachedOfferData[] = [];
      offers.push(...this.cacheService.offer.value.filter(co =>
        co.attributes.name.toLowerCase().replace(/[^A-Z0-9]/ig, '').includes(searchVal.trim())
      ));
      offers.forEach((offer: ISKCachedOfferData) => {
        const parent = this.cacheService.category.value.find(cc => cc.id === offer.parentCategoryId);
        resultArr.push({
          objectId: offer.id,
          display: offer.attributes.name,
          type: 'offer',
          object: offer,
          parent,
          root: this.cacheService.content.value.find(crc => crc.id === parent.parentRootId)
        })
      });

      // Search for categories
      const categories: ISKCachedCategoryData[] = [];
      categories.push(...this.cacheService.category.value.filter(cc =>
        cc.attributes.name.toLowerCase().replace(/[^A-Z0-9]/ig, '').includes(searchVal.trim())
      ))
      categories.forEach((category: ISKCachedCategoryData) => {
        const parent =
          !category.isRootChild
          ? this.cacheService.category.value.find(cc => cc.id === category.parentCategoryId)
          : this.cacheService.content.value.find(crc => crc.id === category.parentRootId)
        resultArr.push({
          objectId: category.id,
          display: category.attributes.name,
          type: 'category',
          object: category,
          parent,
          root: this.cacheService.content.value.find(crc => crc.id === category.parentRootId)
        })
      })
      const end = performance.now();
      //console.log(`Got results in ${(end - start).toFixed(4)}ms!`)
    }

    if (resultArr.length > 0) {
      this.resultsCache[searchVal] = resultArr;
      this.hideContainer = false;
    }
    return resultArr;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

}

export interface ISKSearchResult {
  objectId: number;
  display: string;
  root: ISKRootCategory;
  parent: ISKRootCategory | ISKCachedCategoryData;
  object: ISKCategoryData | ISKOfferData;
  type: 'category' | 'offer';
}

export interface ISKSearchResultLegacy {
  objectId: number;
  display: string;
  rootCategoryRef?: ISKRootCategory;
  categoryRef?: ISKCategoryData;
  offerRef?: ISKOfferData;
}
