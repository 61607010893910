<div class="sk-generic-card-container"
     [style.text-align]="textAlign"
     [class.ghost]="ghostMode"
     [class.left-border]="leftBorder" [class.card-border]="cardBorder"
     [class.sk-mobile-menu]="device.isMobile()">
  <div [style.display]="leftBorder ? 'block' : 'none'" class="left-border-stripe"></div>
  <div class="w-100 d-flex flex-row align-items-center justify-content-between">
    <div [class.title-border]="titleBorder" *ngIf="cardTitle.length > 0" [title]="cardTitle" class="sk-generic-card-container-title text-nowrap">{{cardTitle}}</div>
    <div [class.title-border]="titleBorder" *ngIf="ghostMode && (!cardTitle || cardTitle.length <= 0)" class="sk-generic-card-container-title text-nowrap ghostTitle">SPORTKULTOUR<div class="ghostText"></div></div>

    <div *ngIf="cardTitle.length > 0" class="sk-generic-card-container-date">{{cardAnnotation}}</div>

  </div>
    <img #cardImage *ngIf="!hideImage && !ghostMode" class="sk-generic-card-image" [src]="imageUrl">
    <div *ngIf="ghostMode" class="sk-ghost-image"></div>
    <div class="sk-generic-card-content" [style.padding]="cssContentPadding">
      <ng-content></ng-content>
      <div *ngIf="ghostMode">
        <h5 [style.width]="'95%'"><span class="invisible">SPORT</span><div class="ghostText-p1"></div></h5>
          <h4 [style.width]="'80%'"><span class="invisible">KULTOUR</span><div class="ghostText-p1"></div></h4>
        <div class="w-75 mt-4 mx-auto d-block p-2 text-center placeholder-ghost">
          SPORTKULTOUR
        </div>
      </div>
    </div>
</div>
