import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sk-view-container-content',
  templateUrl: './sk-view-container-content.component.html',
  styleUrls: ['./sk-view-container-content.component.scss']
})
export class SkViewContainerContentComponent implements OnInit {

  @Input() detachChangeDetection = false;

  constructor(
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (this.detachChangeDetection) {
      this.cd.detach();
    }
  }

  public refresh() {
    this.cd.detectChanges();
  }

}
