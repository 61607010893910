import { Component } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import {DeviceDetectorService} from "ngx-device-detector";
import {ContentService, ISKRootCategory} from "../../services/content/content.service";
import {CacheService} from "../../services/cache/cache.service";
import {ISKStaticPageData, StaticPagesService} from "../../services/static-pages/static-pages.service";
import {DataService} from "../../services/data/data.service";
import {environment} from "../../../environments/environment";
import {ISKLocale, LocaleService} from "../../services/locale/locale.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'sk-dialog-cookie-consent',
  templateUrl: './sk-dialog-cookie-consent.component.html',
  styleUrls: ['./sk-dialog-cookie-consent.component.scss']
})
export class SkDialogCookieConsentComponent {

  public essential = true;
  public attributes: any;
  public requestOngoing = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly dataService: DataService,
    private readonly localeService: LocaleService,
    private readonly dialogRef: MatDialogRef<SkDialogCookieConsentComponent>,
    private readonly device: DeviceDetectorService,
    private readonly contentService: ContentService,
    private readonly staticPagesService: StaticPagesService,
    private readonly cacheService: CacheService
  ) {
    this.subscriptions.push(this.localeService.$current.subscribe((locale: ISKLocale) => {
      this.getConsentText();
    }));
    this.dialogRef.addPanelClass(['cookie-diag-panel', 'cookie-fade-in']);
    this.dialogRef.addPanelClass(device.isMobile() ? 'mobile' : 'desktop');
    if (!localStorage.getItem('c')) {
      localStorage.clear();
    }
  }

  public acceptAll() {
    this.cacheService.setCookieConsent(true);
    this.rebuildCaches();
    this.dialogRef.close();
  }

  public save() {
    if (!this.essential) {
      this.cacheService.setCookieConsent(false);
    } else {
      this.cacheService.setCookieConsent(true);
      this.rebuildCaches();
    }
    this.dialogRef.close();
  }

  private rebuildCaches() {
    this.contentService.getMainDataSet().then((data: ISKRootCategory[]) => {
      this.cacheService.buildContentCaches(data).then();
    });
    this.staticPagesService.init(true);
  }

  private getConsentText() {
    this.requestOngoing = true;
    this.dataService.get(environment.apiUrl + '/' + 'cookie-dialog?locale=' + this.localeService.getCurrent().code).then((response: any) => {
      this.dialogRef.removePanelClass('cookie-fade-in');
      if (response && response['data'] && response['data']['attributes']) {
        this.attributes = response['data']['attributes'];
        this.requestOngoing = false;
      } else {
        throw new Error('[SkDialogCookieConsentComponent] FATAL: Couldn\'t get cookie consent text');
      }
    });
  }

}
