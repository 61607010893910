import { Component } from '@angular/core';
import {ISKStaticPageData, ISKStaticPages, StaticPagesService} from "../../services/static-pages/static-pages.service";
import {ActivatedRoute} from "@angular/router";
import {BaseView} from "../baseView";

@Component({
  selector: 'app-sk-payment-options',
  templateUrl: './sk-static-payment-options.component.html',
  styleUrls: ['./sk-static-payment-options.component.scss']
})
export class SkStaticPaymentOptionsComponent extends BaseView {

  public pageData: ISKStaticPageData;
  private readonly endpoint = this.activatedRoute.snapshot.data['endpoint'];

  constructor(
    private readonly staticPagesService: StaticPagesService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    super();
    this.subscriptions.push(this.staticPagesService.staticPageData.subscribe((staticPages: ISKStaticPages) => {
      this.pageData = staticPages[this.endpoint];
    }));
  }

}
