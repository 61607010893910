import { Injectable } from '@angular/core';
import {DataService} from "../data/data.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {environment} from "../../../environments/environment";
import {CacheService, ISKCachedCategoryData, ISKCachedOfferData, ISKContentCaches} from "../cache/cache.service";
import {OffersService} from "../offers/offers.service";
import {ISKLocale, LocaleService} from "../locale/locale.service";

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  private readonly searchDepthLimit = 5;
  private _content: BehaviorSubject<ISKRootCategory[]> = new BehaviorSubject<ISKRootCategory[]>([]);
  public get content(): BehaviorSubject<ISKRootCategory[]> {
    return this._content;
  }
  private subscriptions: Subscription[] = [];

  // Cache
  private CACHE: ISKContentCaches = {
    content: [],
    categories: [],
    offers: [],
    highlights: []
  }

  constructor(
    private readonly localeService: LocaleService,
    private readonly dataService: DataService,
    private readonly cacheService: CacheService,
    private readonly offersService: OffersService,
  ) {
    this.init();
    this.subscriptions.push(this.cacheService.content.subscribe((content: ISKRootCategory[]) => {
      this._content.next(content);
    }));
    this.subscriptions.push(
      this._content.subscribe((content: ISKRootCategory[]) => {
        if (content && content.length > 0) {
          content.forEach((rootCategory: ISKRootCategory) => {
            rootCategory?.attributes?.child_categories?.data.forEach((rootChild: ISKCategoryData) => {
              rootChild.isRootChild = true;
            })
          });
        }
      })
    )
    this.subscriptions.push(this.localeService.$current.subscribe((locale: ISKLocale) => {
      if (!environment.production) {
        console.log(`[ContentService] Init called with locale ${locale.code} (${locale.name})`);
      }
      this.init();
    }));
  }

  public init(): void {
    this.getMainDataSet().then((data) => {
      if (!environment.production) {
        console.log(`[ContentService] Got dataset from API`, data)
      }
      this._content.next(data);
      this.cacheService.buildContentCaches(data).then((cache: ISKContentCaches | null) => {
        console.log('[ContentService]: getMainDataSet() finished, buildContentCaches() finished: ', cache);
        if (cache) {
          this.CACHE = cache;
        }
      });
    });
  }

  public getMainDataSet(): Promise<ISKRootCategory[]> {
    const population = [
      "image",
      "offers.description",

      "child_categories.image",
      "child_categories.offers.description",

      "child_categories.child_categories.image",
      "child_categories.child_categories.offers.description",

      "child_categories.child_categories.child_categories.image",
      "child_categories.child_categories.child_categories.offers.description",

      "child_categories.child_categories.child_categories.child_categories.image",
      "child_categories.child_categories.child_categories.child_categories.offers.description"
    ]

    const api_params: any = {
      "filters[root_category][$eq]": true,
      "populate": population.toString(),
      "locale": this.localeService.getCurrent().code
    }

    let param_list = []

    for (let key in api_params) {
      param_list.push(`${key}=${api_params[key]}`)
    }

    const uri = environment.apiUrl + '/categories?' + param_list.join("&");
    return this.dataService.get(uri).then((data) => {
      const d = (data as {data: ISKRootCategory[]});
      const c = d?.data ? d.data : null;
      if (!c || c.length <= 0) {
        console.warn('Warning: No data received while acquiring main data set');
      }
      return c;
    });
  }

  public getAllOffers(data: ISKRootCategory[]) {
    const result: ISKOfferData[] = [];
    data.forEach(rootCategory => {
      result.push(...this.getOffersForCategory(rootCategory, rootCategory, true));
    });
    return result;
  }

  public getRootForCategory(categoryId: number): ISKRootCategory {
    let result: ISKRootCategory;
    this._content.value.forEach(rootCategory => {
      rootCategory.attributes.child_categories.data.forEach(category => {
        if (result) {
          return;
        }
        if (this.searchCategory(categoryId, category)) {
          result = rootCategory;
        }
      })
    })
    return result as ISKRootCategory;
  }

  private searchCategory(searchId: number, category: ISKCategoryData, depth = this.searchDepthLimit): boolean {
    let result = false;
    if (category.id === searchId) {
      result = true;
    } else {
      if (depth > 0 && category.attributes.child_categories?.data) {
        category.attributes.child_categories.data.forEach(childCategory => {
          if (result) {
            return;
          } else {
            result = this.searchCategory(searchId, childCategory, depth - 1);
          }
        })
      }
    }
    return result;
  }

  public offersByParent(rootCategory?: ISKRootCategory): {[key: string]: ISKOfferData[]} {
    let result: {[key: string]: ISKOfferData[]} = {};
    const getOffers = (category: ISKCategoryData, depth = this.searchDepthLimit) => {
      let o: {[key: string]: ISKOfferData[]} = {};
      if (depth > 0) {
        if (category?.attributes?.offers?.data.length > 0) {
          o[category.attributes.name] = [];
          category.attributes.offers.data.forEach(offer => {
            o[category.attributes.name].push(offer);
          })
        }
        const children = category?.attributes?.child_categories;
        children?.data?.forEach(child => {
          o = {...o, ...getOffers(child, depth - 1)}
        });
      }
      return o;
    }
    try{
      const root = rootCategory ? [rootCategory] : this._content.value;
      root.forEach(rootItem => {
        const rootCategoryChildren = rootItem.attributes.child_categories;
        rootCategoryChildren?.data?.forEach(category => {
          result = {...result, ...getOffers(category)}
        });
      });

    } catch (err) {
      console.error('Couldn\'t build offers object sorted by parent: ', err);
    }

    return result;
  }

  public getOfferParent(offer: ISKOfferData): ISKCategoryData | ISKRootCategory {
    try {
      const cachedOffer = this.CACHE?.offers?.find(co => co.id === offer.id);
      const offerParent = this.CACHE.categories.find(cc => cc.id == cachedOffer?.parentCategoryId);
      if (offerParent) {
        return offerParent;
      } else {
        if (!environment.production) {
          console.warn('Couldn\'t find parent for offer: ', offer);
        }
        return null;
      }
    } catch (err) {
      if (!environment.production) {
        console.error('Error while searching parent for offer ', err);
      }
      return null;
    }
  }

  public getOffersForCategory(category: ISKCachedCategoryData | ISKRootCategory, rootCategory: ISKRootCategory, noCache = false, depth = this.searchDepthLimit): ISKCachedOfferData[] {
    const result: ISKCachedOfferData[] = [];
    category = category ? category : this.offersService.selectedRootCategory.value;
    const fromCache = this.cacheService.offer.value.filter(co => co.parentCategoryId === category?.id);
    if (fromCache) {
      result.push(...fromCache);
    }
    return fromCache;
  }

  public getCategoriesForRootCategory(rootCat: ISKRootCategory): ISKCategoryData[] {
    const catArr: ISKCategoryData[] = [];
    try {
      rootCat.attributes.child_categories.data.forEach(cat => {
        catArr.push(cat);
      })
    } catch (err) {
      console.error('ERROR in ContentService.getCategoriesForRootCategory -> ', err);
    }
    return catArr;
  }

}

export interface ISKRootCategory {
  id: number;
  attributes: ISKRootCategoryAttributes
}

export interface ISKRootCategoryAttributes {
  id: number;
  name: string;
  image?: ISKCategoryImage;
  child_categories: ISKCategory;
  highlight?: boolean;
  offers: ISKOffer;
}

export interface ISKCategory {
  data: ISKCategoryData[];
}

export interface ISKCategoryData {
  id: number;
  isRootChild?: boolean;
  attributes: ISKCategoryAttributes;
}

export interface ISKCategoryAttributes {
  name: string;
  createdAt: string;
  description: string;
  image: ISKCategoryImage;
  child_categories: ISKCategory;
  highlight?: boolean;
  offers: ISKOffer;
}

export interface ISKOffer {
  data: ISKOfferData[];
}

export interface ISKOfferData {
  id: number;
  attributes: ISKOfferAttributes;
}

export interface ISKHighlight {
  type: 'rootCategory' | 'category' | 'offer' | 'ghost';
  guid: string;
  image?: string;
  parentId?: number;
  parentRootId?: number;
  highlightObject: ISKOfferData | ISKCategoryData | ISKRootCategory;
}

export interface ISKOfferAttributes {
  name: string;
  createdAt: string;
  updatedAt: string;
  expiration_date: string;
  date: string;
  highlight: boolean;
  description: ISKOfferDataDescription[];
}

export interface ISKOfferDataDescription {
  id: number;
  package: string;
  package_title: string;
  price: number;
  price_alternative_text: string;
  display_price: boolean;
  price_info: string;
}

export interface ISKCategoryImage {
  data: ISKCategoryImageData[];
}

export interface ISKCategoryImageData {
  id: number;
  attributes: ISKCategoryImageAttributes;
}

export interface ISKCategoryImageAttributes {
  mime: string;
  name: string;
  url: string;
  ext: string;
  formats: {
    small: {
      url: string;
    },
    medium: {
      url: string;
    },
    large: {
      url: string;
    }
  }
}
