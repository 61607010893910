import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {UiService} from "../../services/ui/ui.service";

@Component({
  selector: 'sk-view-container-side-menu',
  templateUrl: './sk-view-container-side-menu.component.html',
  styleUrls: ['./sk-view-container-side-menu.component.scss']
})
export class SkViewContainerSideMenuComponent implements OnInit {

  constructor(
    public readonly ui: UiService
  ) { }

  ngOnInit(): void {
  }


}
