<div id="sk-carousel-container" class="position-relative w-100 d-flex flex-row">
  <div #carousel id="sk-carousel" class="mx-auto">
    <div *ngFor="let highlight of highlights; let idx = index;" class="sk-carousel-image" [class.hidden]="carouselIdx !== idx" >
      <div class="sk-carousel-item-text" (click)="navigateToHighlight(highlight)">
        <h3 class="sk-carousel-item-text-item" [style.font-size]="'3em'" [innerText]="highlight.highlightObject?.attributes?.name"></h3><br />
        <h4 *ngIf="getParentForHighlight(highlight)?.attributes?.name" class="sk-carousel-item-text-item" [style.font-size]="'2em'" [innerText]="getParentForHighlight(highlight)?.attributes?.name"></h4>
      </div>
      <img [src]="highlights[carouselIdx].image">
    </div>
    <div #carouselNavigation id="sk-carousel-navigation" class="sk-carousel-navigation">
      <div *ngFor="let highlight of highlights; let idx = index;" (click)="setCarouselIdx(idx)" class="sk-carousel-navigation-item" [class.highlighted]="idx === carouselIdx">
        <span [class.d-none]="!smallNavigationOptions" [innerText]="idx + 1"></span>
        <span [class.d-none]="smallNavigationOptions" [innerText]="highlight.highlightObject?.attributes?.name"></span>
      </div>
    </div>
    <div id="sk-carousel-chevrons" *ngVar="['chevron-left', 'chevron-right'] as chevrons">
      <sk-svg-icon [icon]="chevrons[0]" [cssElementSize]="'64px'" [cssIconSize]="'180%'" (click)="setCarouselIdx(carouselIdx - 1)"></sk-svg-icon>
      <sk-svg-icon [icon]="chevrons[1]" [cssElementSize]="'64px'" [cssIconSize]="'180%'" (click)="setCarouselIdx(carouselIdx + 1)"></sk-svg-icon>
    </div>
    <div #skCarouselTimerBar id="sk-carousel-timer-bar"></div>
  </div>
  <div id="sk-carousel-blur">
    <div *ngFor="let highlight of highlights; let idx = index;" class="sk-carousel-blur-img" [style.background-image]="'url(' + highlights[carouselIdx].image + ')'" [class.hidden]="carouselIdx !== idx"></div>
<!--    <img *ngFor="let highlight of highlights; let idx = index;" [src]="highlights[carouselIdx].image" [class.hidden]="carouselIdx !== idx">-->
  </div>
</div>
