import { Component } from '@angular/core';
import {BaseView} from "../baseView";
import {
  ISKGenericStaticPageAttributes,
  ISKStaticPages,
  StaticPagesService
} from "../../services/static-pages/static-pages.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-sk-static-terms-of-service',
  templateUrl: './sk-static-terms-of-service.component.html',
  styleUrls: ['./sk-static-terms-of-service.component.scss']
})
export class SkStaticTermsOfServiceComponent extends BaseView {

  private readonly endpoint = this.activatedRoute.snapshot.data['endpoint'];
  public pageData: ISKGenericStaticPageAttributes;

  constructor(
    private readonly staticPagesService: StaticPagesService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    super();
    this.subscriptions.push(this.staticPagesService.staticPageData.subscribe((staticPages: ISKStaticPages) => {
      const pageData = staticPages[this.endpoint];
      if (pageData) {
        this.pageData = pageData.attributes;
      }
    }));
  }

}
