import {Component, OnInit, Renderer2 } from '@angular/core';
import {BaseView} from "../baseView";
import {UiService} from "../../services/ui/ui.service";
import {
  ContentService,
  ISKCategoryData,
  ISKHighlight, ISKOfferData,
  ISKRootCategory
} from "../../services/content/content.service";
import {DataService} from "../../services/data/data.service";
import {routes} from "../../app-routing.module";
import {CacheService, ISKCachedOfferData} from "../../services/cache/cache.service";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from "../../../environments/environment";
import {DeviceDetectorService} from "ngx-device-detector";
import {UtilsService} from "../../services/utils/utils.service";
import {ISKLocale, LocaleService} from "../../services/locale/locale.service";

@Component({
  selector: 'app-sk-landing',
  templateUrl: './sk-landing.component.html',
  styleUrls: ['./sk-landing.component.scss']
})
export class SkLandingComponent extends BaseView implements OnInit {

  public locales: ISKLocale[] = [];
  public offersRoute = routes.find(r => r.path === 'offers');
  public readonly introTextUrl = '/assets/markdown/introtext.md';
  public readonly maxWidth = this.ui.pageMaxWidth;
  public readonly mobile = this.device.isMobile();
  public content: ISKRootCategory[] = [];
  public highlights: ISKHighlight[] = [];
  // highlightsPadded will be filled with additional data only containing {id: -1}
  public highlightsPadded: ISKHighlight[] = [];
  public logoImgSrc = environment.domain + '/assets/images/logo.png';

  constructor(
    public readonly ui: UiService,
    public readonly utils: UtilsService,
    private readonly renderer: Renderer2,
    private readonly dataService: DataService,
    private readonly router: Router,
    private readonly cacheService: CacheService,
    private readonly contentService: ContentService,
    private readonly localeService: LocaleService,
    private readonly device: DeviceDetectorService,
    private readonly snackBar: MatSnackBar
  ) {
    super();
  }

  ngOnInit(): void {
    this.locales = this.localeService.getLocales();

    this.subscriptions.push(this.cacheService.content.subscribe((cachedContent: ISKRootCategory[]) => {
      this.content = cachedContent;
    }));

    this.subscriptions.push(this.cacheService.offer.subscribe((cachedOffers: ISKCachedOfferData[]) => {
    }));

    this.subscriptions.push(this.cacheService.highlight.subscribe((cachedHighlights: ISKHighlight[]) => {
      this.highlights = cachedHighlights;
      //console.log('[SkLandingComponent] updated cachedHighlights:', cachedHighlights)
      this.highlightsPadded = JSON.parse(JSON.stringify(this.highlights)).filter((highlight: ISKHighlight) => highlight.type === 'offer'); // Deep copy!
      const highlightsPaddedCount = this.highlightsPadded.length;
      if (highlightsPaddedCount < 8) {
        let fillLength = 0;
        if (highlightsPaddedCount > 0 && highlightsPaddedCount <= 4) {
          fillLength = 4 - highlightsPaddedCount;
        }
        if (highlightsPaddedCount > 4 && highlightsPaddedCount <= 8) {
          fillLength = 8 - highlightsPaddedCount;
        }
        const dummy = {type: 'ghost'} as ISKHighlight;
        for (let i = 0; i < fillLength; i++) {
          this.highlightsPadded.push(dummy);
        }
      }
    }));
  }

  public getTranslation(s: string): string {
    return this.localeService.getField(s);
  }

  public navToOffers(offer: ISKHighlight) {
    try {
      const cachedOffer = this.cacheService.highlight.value.find(co => co.guid === offer.guid);
      this.router.navigate(['offers'], {state: {
        select: {
            'id': cachedOffer.highlightObject.id,
            'type': 'offer',
            'object': cachedOffer.highlightObject,
          }
        }
      }).then(() => {
      })
    } catch (err) {
      const message = 'Fehler'
      //console.log(this.ui.snackBarConfig);
      this.snackBar.open(message, 'OK', this.ui.snackBarConfig);
      console.error(err);
    }
  }

  public sendOfferToBookingForm(offer: any) {
    //console.log('sendOfferToBookingForm: ', offer);
    try {
      const cachedOffer = this.cacheService.offer.value.find(co => co.id === offer.id)
      //console.log('cachedOffer: ', cachedOffer);
      this.router.navigate(['booking-form'], {state: {
          offer: {
            offer: cachedOffer,
          }
        }
      }).then(() => {
      })
    } catch (err) {
      const message = 'Fehler'
      //console.log(this.ui.snackBarConfig);
      this.snackBar.open(message, 'OK', this.ui.snackBarConfig);
      console.error(err);
    }
  }

  public getLowestPriceForHighlight(highlight: ISKHighlight): number {
    const prices: number[] = [];
    if (highlight.type === 'offer') {
      const highlightOffer = highlight.highlightObject as ISKOfferData;
      const description = highlightOffer.attributes.description;
      if (description && description.length > 0) {
        description.forEach(description => {
          let p = description.price;
          if (typeof(p).toLocaleString() === 'string') {
            p = parseInt(p + '', 10);
          }
          prices.push(p);
        });
      }
    }
    const lowestPrice = prices.sort((a, b) => {
      if(a > b) { return -1; }
      if(a < b) { return 1; }
      return 0;
    })[0];
    return lowestPrice;
  }

  public setLocale(locale: ISKLocale) {
    this.localeService.setCurrent(locale, false);
  }

}
