import {ChangeDetectorRef, Component} from '@angular/core';
import {
  StaticPagesService,
  ISKStaticPageData,
  ISKGenericStaticPageAttributes
} from "../../services/static-pages/static-pages.service";
import { Route, Router } from "@angular/router";
import { routes } from "../../app-routing.module";
import { BaseView } from "../../views/baseView";
import {DataService} from "../../services/data/data.service";
import {environment} from "../../../environments/environment";
import {ISKCategoryImageData} from "../../services/content/content.service";
import {ISKLocale, LocaleService} from "../../services/locale/locale.service";

@Component({
  selector: 'sk-footer',
  templateUrl: './sk-footer.component.html',
  styleUrls: ['./sk-footer.component.scss']
})
export class SkFooterComponent extends BaseView {

  public pageData: ISKGenericStaticPageAttributes | undefined;
  public readonly paymentOptsRoute: string = routes.find(r => r.path === 'payment-option')?.path;
  public readonly logoImages: string[] = [];
  public staticPages: {endpoint: string, display: string; data: ISKStaticPageData}[] = [];

  constructor(
    private readonly router: Router,
    private readonly staticPagesService: StaticPagesService,
    private readonly dataService: DataService,
    private readonly localeService: LocaleService,
    private readonly cd: ChangeDetectorRef
  ) {
    super();
    this.loadFooterData().then();
    this.subscriptions.push(localeService.$current.subscribe((locale: ISKLocale) => {
      this.loadFooterData().then();
    }));
    this.subscriptions.push(staticPagesService.staticPageData.subscribe(() => {
      this.staticPages = [];
      routes.filter(r => r.data && r.data['endpoint']).forEach((route: Route) => {
        const endpoint = route.data['endpoint'];
        const data = this.staticPagesService.getStaticPageData(endpoint);
        const idx = this.staticPages.findIndex(sp => sp.endpoint === endpoint);
        if (data && endpoint && idx < 0) {
          const display = routes.find(r => r.path === endpoint)?.data['title'] || data?.attributes?.title;
          if (display) {
            this.staticPages.push({endpoint, display, data});
          }
        }
      })
    }));
  }
  private async loadFooterData(): Promise<void> {
    this.dataService.get(environment.apiUrl + '/footer?populate=partner_logos&locale=' + this.localeService.getCurrent().code).then((data: any) => {
      const pageData = data?.data as ISKStaticPageData;
      if (pageData) {
        this.pageData = pageData.attributes;
        (pageData.attributes['partner_logos'].data as ISKCategoryImageData[]).forEach((image: ISKCategoryImageData) => {
          const url = environment.cmsUrl + image.attributes.url;
          if (!this.logoImages.includes(url)) {
            this.logoImages.push(url);
            this.staticPagesService.addPartnerLogoImg(url);
          }
        });
      }
      this.cd.detectChanges();
    })
  }
  public navigate(route: string) {
    this.router.navigate([route]).then();
  }
}
