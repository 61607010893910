import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import md5 from 'crypto-js/md5';
import * as JSLZString from 'lz-string';

import {
  ISKCategoryData,
  ISKHighlight,
  ISKOfferData,
  ISKRootCategory
} from "../content/content.service";
import {BehaviorSubject, of} from "rxjs";
import {UtilsService} from "../utils/utils.service";
import {ISKStaticPageData, ISKStaticPages} from "../static-pages/static-pages.service";

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private cookiesAccepted: boolean;
  private readonly searchDepthLimit = 10;
  private readonly staticPagesPrefix = 'static-page-'
  public content: BehaviorSubject<ISKRootCategory[]> = new BehaviorSubject<ISKRootCategory[]>([]);
  public category: BehaviorSubject<ISKCachedCategoryData[]> = new BehaviorSubject<ISKCachedCategoryData[]>([]);
  public offer: BehaviorSubject<ISKCachedOfferData[]> = new BehaviorSubject<ISKCachedOfferData[]>([]);
  public highlight: BehaviorSubject<ISKHighlight[]> = new BehaviorSubject<ISKHighlight[]>([]);
  public staticPages: BehaviorSubject<ISKStaticPages> = new BehaviorSubject<ISKStaticPages>({});

  constructor(
    private readonly utils: UtilsService,
  ) {
    this.cookiesAccepted = !!localStorage.getItem('c');
    this.content.next(this.getContentCache());
    this.category.next(this.getCategoryCache());
    this.offer.next(this.getOfferCache());
    this.highlight.next(this.getHighlightCache());
    this.staticPages.next(this.getStaticPagesCache());
  }

  public setCookieConsent(c: boolean) {
    this.cookiesAccepted = c;
    if (c) {
      localStorage.setItem('c', Date.now().toString());
    }
  }

  public getCookieConsent(): boolean {
    return !!localStorage.getItem('c');
  }

  public async buildContentCaches(content: ISKRootCategory[]): Promise<ISKContentCaches | null> {
    const cache = this.contentCacheBuilder(content);
    if (cache) {
      this.content.next(cache.content);
      this.category.next(cache.categories);
      this.offer.next(cache.offers);
      this.highlight.next(cache.highlights);
    }
    return cache;
  }

  public async getCaches(): Promise<ISKContentCaches> {
    return {
      content: this.getContentCache(),
      categories: this.getCategoryCache(),
      offers: this.getOfferCache(),
      highlights: this.getHighlightCache()
    }
  }

  public getContentCache(): ISKRootCategory[] {
    try {
      console.log(`[CacheService] Getting content cache from localStorage...`);
      const cachedContent = this.getFromLocalStorage<ISKRootCategory[]>('contentCache');
      const cacheSize = (new Blob([JSON.stringify(cachedContent)])).size;
      if (cachedContent && cachedContent.length > 0) {
        console.log(`[CacheService] Getting content cache from localStorage... done! (${cachedContent.length} items, ${this.utils.formatBytes(cacheSize)})`);
        return cachedContent;
      } else {
        console.log(`[CacheService] Getting content cache from localStorage... done! (Empty)`);
        return [];
      }
    } catch (err) {
      return this.cacheAccessError('content', 'getting', err);
    }
  }

  public getCategoryCache(): ISKCachedCategoryData[] {
    try {
      console.log(`[CacheService] Getting category cache from localStorage...`);
      const categoryCache = this.getFromLocalStorage<ISKCachedCategoryData[]>('categoryCache');
      const cacheSize = (new Blob([JSON.stringify(categoryCache)])).size;
      if (categoryCache && categoryCache.length > 0) {
        console.log(`[CacheService] Getting category cache from localStorage... done! (${categoryCache.length} items, ${this.utils.formatBytes(cacheSize)})`);
        return categoryCache;
      } else {
        console.log(`[CacheService] Getting category cache from localStorage... done! (Empty)`);
        return [];
      }
    } catch (err) {
      return this.cacheAccessError('category', 'getting', err);
    }
  }

  public getOfferCache(): ISKCachedOfferData[] {
    try {
      console.log(`[CacheService] Getting offer cache from localStorage...`);
      const offerCache = this.getFromLocalStorage<ISKCachedOfferData[]>('offerCache');
      const cacheSize = (new Blob([JSON.stringify(offerCache)])).size;
      if (offerCache && offerCache.length > 0) {
        console.log(`[CacheService] Getting offer cache from localStorage... done! (${offerCache.length} items, ${this.utils.formatBytes(cacheSize)})`);
        return offerCache;
      } else {
        console.log(`[CacheService] Getting offer cache from localStorage... done! (Empty)`);
        return [];
      }
    } catch (err) {
      return this.cacheAccessError('offer', 'getting', err);
    }
  }

  public getHighlightCache(): ISKHighlight[] {
    try {
      console.log(`[CacheService] Getting highlight cache from localStorage...`);
      const highlightCache = this.getFromLocalStorage<ISKHighlight[]>('highlightCache');
      const cacheSize = (new Blob([JSON.stringify(highlightCache)])).size;
      if (highlightCache && highlightCache.length > 0) {
        console.log(`[CacheService] Getting highlight cache from localStorage... done! (${highlightCache.length} items, ${this.utils.formatBytes(cacheSize)})`);
        return highlightCache;
      } else {
        console.log(`[CacheService] Getting highlight cache from localStorage... done! (Empty)`);
        return [];
      }
    } catch (err) {
      return this.cacheAccessError('highlight', 'getting', err);
    }
  }

  public getStaticPagesCache(): ISKStaticPages | null {
    try {
      console.log(`[CacheService] Getting cache for all static pages from localStorage...`);
      const result: ISKStaticPages = {};
      Object.keys(localStorage).filter(key => key.startsWith(this.staticPagesPrefix)).forEach((staticPageCacheItem: string) => {
        result[staticPageCacheItem.replace(this.staticPagesPrefix, '')] = JSON.parse(this.getFromLocalStorage(staticPageCacheItem));
      });
      console.log(`[CacheService] Getting cache for all static pages from localStorage... done:`, result);
      return result;
    } catch (err) {
      this.cacheAccessError('staticPage', 'getting', err);
      return null;
    }
  }

  public getStaticPageCache(key: string): ISKStaticPageData | null {
    try {
      console.log(`[CacheService] Getting cache for static page ${key} from localStorage...`);
      return JSON.parse(this.getFromLocalStorage(this.staticPagesPrefix + key));
    } catch (err) {
      this.cacheAccessError('staticPage', 'getting', err);
      return null;
    }
  }

  public staticPagesCacheBuilder(staticPages: ISKStaticPages): void {
    const startTime = performance.now();
    try {
      const prefix = 'static-page-'
      Object.keys(staticPages).forEach((key: string) => {
        console.log(`[CacheService] Caching static page: ${key}`);
        if (this.cookiesAccepted) {
          this.writeToLocalStorage(prefix + key, JSON.stringify(staticPages[key]));
        }
      });
    } catch (err) {
      if (!environment.production) {
        console.error('[CacheService] STATIC PAGES CACHE BUILDER FAILURE. THIS IS A FATAL ERROR.', err)
      } else {
        console.error('[CacheService] STATIC PAGES CACHE BUILDER FAILURE. THIS IS A FATAL ERROR.')
      }
    } finally {
      if (!environment.production) {
        const endTime = performance.now();
        console.log(`[CacheService] Static Pages Cache Builder has finished in ${((endTime - startTime) / 1000).toFixed(2)}seconds (${(endTime - startTime).toFixed(4)}ms)`);
      }
    }

  }

  // BUILD ALL CACHES AT ONCE
  private contentCacheBuilder(content: ISKRootCategory[]): ISKContentCaches {
    if (!content || content.length <= 0) {
      // Delta if no  content
      console.warn('Content cache builder failure');
      return null;
    }
    const startTime = performance.now();
    const cache: ISKContentCaches = {
      content: [...content],
      categories: [],
      offers: [],
      highlights: []
    };
    try {
      content.forEach((rootCategory: ISKRootCategory) => {
        rootCategory?.attributes?.child_categories?.data.forEach((category: ISKCategoryData) => {
          const result = this.recursiveCacheBuilder(rootCategory, category);
          cache.categories.push(...result?.categories);
          cache.offers.push(...result?.offers);
          cache.highlights.push(...result?.highlights);
        });
      })

      // Write Content Cache to Local Storage
      const contentCacheStr = JSON.stringify(cache.content);
      const contentCacheSize = (new Blob([contentCacheStr])).size;
      localStorage.removeItem('contentCache');

      if (this.cookiesAccepted) {
        this.writeToLocalStorage('contentCache', contentCacheStr);
        console.log(`[CacheService] Content cache added to local storage (${cache.content.length} items, ${this.utils.formatBytes(contentCacheSize)})`, cache.content);
      }
      // Write Category Cache to Local Storage
      const categoryCacheStr = JSON.stringify(cache.categories);
      const categoryCacheSize = (new Blob([categoryCacheStr])).size;
      localStorage.removeItem('categoryCache');
      if (this.cookiesAccepted) {
        this.writeToLocalStorage('categoryCache', categoryCacheStr);
        console.log(`[CacheService] Category cache added to local storage (${cache.categories.length} items, ${this.utils.formatBytes(categoryCacheSize)})`, cache.categories);
      }
      // Write Offer Cache to Local Storage
      const offerCacheStr = JSON.stringify(cache.offers);
      const offerCacheSize = (new Blob([offerCacheStr])).size;
      localStorage.removeItem('offerCache');
      if (this.cookiesAccepted) {
        this.writeToLocalStorage('offerCache', offerCacheStr);
        console.log(`[CacheService] Offer cache added to local storage (${cache.offers.length} items, ${this.utils.formatBytes(offerCacheSize)})`, cache.offers);
      }
      // Write Highlight Cache to Local Storage
      const highlightCacheStr = JSON.stringify(cache.highlights);
      const highlightCacheSize = (new Blob([highlightCacheStr])).size;
      localStorage.removeItem('highlightCache');
      if (this.cookiesAccepted) {
        this.writeToLocalStorage('highlightCache', highlightCacheStr);
        console.log(`[CacheService] Highlight cache added to local storage (${cache.highlights.length} items, ${this.utils.formatBytes(highlightCacheSize)})`, cache.highlights);
      }

    } catch (err) {
      if (!environment.production) {
        console.error('[CacheService] CONTENT CACHE BUILDER FAILURE. THIS IS A FATAL ERROR.', err)
      } else {
        console.error('[CacheService] CONTENT CACHE BUILDER FAILURE. THIS IS A FATAL ERROR.')
      }
    } finally {
      if (!environment.production) {
        const endTime = performance.now();
        console.log(`[CacheService] Content Cache Builder has finished in ${((endTime - startTime) / 1000).toFixed(2)}seconds (${(endTime - startTime).toFixed(4)}ms): `, cache);
      }
      return cache;
    }
  }

  private writeToLocalStorage(key: string, data: string, compression = true) {
    try {
      const keyHash = md5(key).toString();
      localStorage.removeItem(keyHash);
      if (compression) {
        const compressed = JSLZString.compressToBase64(data);
        localStorage.setItem(keyHash, compressed);
        if (!environment.production) {
          const uncompressedDataSize = this.utils.formatBytes(new Blob([data]).size);
          const compressedDataSize = this.utils.formatBytes(new Blob([compressed]).size);
          console.log('[CacheService] Added "' + key + '" to local Storage...');
          console.log('               Uncompressed size (len): ', uncompressedDataSize);
          console.log('               Compressed size (len):   ', compressedDataSize + ' (' + Math.round((100 / data.length) * compressed.length) + '%)');
          console.log('               Compression efficiency:  ', Math.round(100 - ((100 / data.length) * compressed.length)) + '%');
        }
      } else {
        localStorage.setItem(keyHash, JSON.stringify(data));
      }
    } catch (err) {
      if (environment.production) {
        console.warn('There was a problem saving cached data to local storage')
      } else {
        console.warn('[CacheService] There was a problem saving cached data to local storage: ', err)
      }
    }
  }

  private getFromLocalStorage<T>(key: string, decompress = true): T {
    try {
      key = md5(key).toString();
      if (decompress) {
        const compressed = localStorage.getItem(key);
        if (compressed) {
          const decompressed = JSLZString.decompressFromBase64(compressed);
          const parsed = JSON.parse(decompressed);
          return <T>parsed;
        } else {
          return null;
        }
      } else {
        const storageItem = localStorage.getItem(key);
        const parsed = JSON.parse(storageItem);
        return <T>parsed;
      }
    } catch (err) {
      if (environment.production) {
        console.warn('There was a problem reading cached data from local storage')
      } else {
        console.warn('[CacheService] There was a problem reading cached data from local storage: ', err)
      }
      return null;
    }
  }

  private recursiveCacheBuilder(rootCategory: ISKRootCategory, category: ISKCategoryData, parent?: ISKCachedCategoryData, depth = this.searchDepthLimit): ISKContentCaches {
    const CACHES: ISKContentCaches = {
      content: [],
      categories: [],
      offers: [],
      highlights: []
    }

    if (depth <= 0) {
      return CACHES;
    }

    // Add root category to cache
    if (CACHES.content.findIndex(crc => crc.id === rootCategory.id) < 0) {
      CACHES.content.push(rootCategory);
    }

    // Add category to cache
    if (CACHES.categories.findIndex(cc => cc.id === category.id) < 0) {
      category.isRootChild = depth === this.searchDepthLimit;
      CACHES.categories.push({
        ...category,
        guid: this.makeCacheItemName(category),
        parentCategoryId: parent?.id,
        parentRootId: rootCategory.id
      });
    }

    // Add category itself to highlights cache if available and marked as such
    if(category.attributes.highlight) {
      CACHES.highlights.push({
        type: 'category',
        guid: this.makeCacheItemName(category),
        image: category?.attributes?.image?.data ? (environment.cmsUrl + category?.attributes?.image?.data[0]?.attributes?.url) : environment.placeholderImage,
        parentId: parent?.id,
        highlightObject: category
      })
    }

    // Add offers to cache
    const offers = category.attributes?.offers?.data;
    if (offers && offers.length > 0) {
      offers.forEach((offer: ISKOfferData) => {
        const expiredOffer: boolean = offer?.attributes?.expiration_date ? (new Date(offer.attributes.expiration_date).getTime() < (new Date).getTime()) : false;
        if (!expiredOffer && (CACHES.offers.findIndex(o => o.id === offer.id) < 0)) {
          CACHES.offers.push({
            ...offer,
            guid: this.makeCacheItemName(offer),
            categoryGuid: this.makeCacheItemName(category),
            parentCategoryId: category?.id,
          });
        }
      })
    }

    // Add offers to highlights if available and marked as such
    if(category.attributes.offers?.data?.length > 0) {
      category.attributes.offers.data.forEach((offer: ISKOfferData) => {
        if(offer.attributes.highlight) {
          const imageData = category?.attributes?.image?.data;
          CACHES.highlights.push({
            type: 'offer',
            guid: this.makeCacheItemName(category),
            image: (imageData && imageData[0] ? (environment.cmsUrl + imageData[0]?.attributes?.url) : environment.placeholderImage),
            parentId: category.id,
            parentRootId: rootCategory.id,
            highlightObject: offer
          })
        }
      })
    }

    // Recursive search in child categories
    category.attributes?.child_categories?.data?.forEach((childCategory: ISKCategoryData) => {
      const result = this.recursiveCacheBuilder(rootCategory, childCategory, {...category, guid: this.makeCacheItemName(category), parentCategoryId: parent?.id, parentRootId: rootCategory.id}, depth - 1);

      // Skipping Root Categories here

      // Categories
      const resultCategories = result.categories;
      if (resultCategories && resultCategories.length > 0) {
        CACHES.categories.push(...resultCategories);
      }

      // Offers
      const resultOffers = result.offers;
      if (resultOffers && resultOffers.length > 0) {
        CACHES.offers.push(...resultOffers);
      }

      // Highlights
      const resultHighlights = result.highlights;
      if (resultHighlights && resultHighlights.length > 0) {
        CACHES.highlights.push(...resultHighlights);
      }

    })
    return CACHES;
  }

  public makeCacheItemName(item: ISKCategoryData | ISKRootCategory | ISKOfferData) {
    try {
      return item.id + (item.attributes.name.replace(/[^A-Z0-9]+/ig, ""));
    } catch (err) {
      console.error('Error while generating cache item name. Item: ', item, err);
      return null;
    }
  }

  // ERROR HANDLING

  private cacheAccessError(cacheType: string, accessType: 'building' | 'getting', err?: unknown): [] {
    this.errorMsg(`Error while ${accessType} ${cacheType} cache. Engaging fallback by returning empty array.`, err);
    return [];
  }

  private errorMsg(msg: string, err?: unknown) {
    if (!environment.production) {
      console.error(msg, err);
    } else {
      console.error(msg);
    }

  }

}

export interface ISKCachedCategoryData extends ISKCategoryData {
  guid: string;
  parentRootId: number;
  parentCategoryId: number;
}

export interface ISKCachedOfferData extends ISKOfferData {
  guid: string;
  categoryGuid: string;
  parentCategoryId: number;
}

export interface ISKContentCaches {
  content: ISKRootCategory[];
  categories: ISKCachedCategoryData[];
  offers: ISKCachedOfferData[];
  highlights: ISKHighlight[];
}

