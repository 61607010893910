import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DeviceDetectorService} from "ngx-device-detector";
import {UiService} from "../../services/ui/ui.service";
import {DataService} from "../../services/data/data.service";
import {environment} from "../../../environments/environment";
import {ISKCategoryData, ISKRootCategory} from "../../services/content/content.service";
import {CacheService} from "../../services/cache/cache.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'sk-generic-card',
  templateUrl: './sk-generic-card.component.html',
  styleUrls: ['./sk-generic-card.component.scss']
})
export class SkGenericCardComponent implements OnInit, OnDestroy {

  @Input() public leftBorder = false;
  @Input() public titleBorder = false;
  @Input() public cardBorder = true;
  @Input() public ghostMode = false;
  @Input() public cardTitle: string = '';
  @Input() public cardAnnotation: string = '';
  @Input() public imageUrl: string | undefined;
  @Input() public imageItem: ISKRootCategory | ISKCategoryData | undefined;
  @Input() public allowRecursiveImageSearch: boolean = false;
  @Input() public hideImage: boolean = false;
  @Input() public cssContentPadding: string | undefined;
  @Input() public textAlign: 'center' | 'justify' | 'left' | 'right' = 'left';
  @Input() public cssCustomHeight: string;
  @Input() public cssCustomWidth: string;

  private subscriptions: Subscription[] = [];

  constructor(
    public readonly elementRef: ElementRef,
    public readonly device: DeviceDetectorService,
    public readonly dataService: DataService,
    public readonly ui: UiService,
    private readonly renderer: Renderer2,
    private readonly cacheService: CacheService,
    private readonly cd: ChangeDetectorRef
  ) { }

  async ngOnInit(): Promise<void> {

    this.subscriptions.push(this.cacheService.offer.subscribe(() => {
      this.cd.detectChanges();
    }));

    const self = this.elementRef.nativeElement as HTMLElement;
    if (this.device.isMobile()) {
      this.renderer.addClass(self, 'sk-mobile-menu');
    }
    if (this.ghostMode) {
      this.imageUrl = environment.solidColorImage;
    }
    if (this.cssCustomHeight) {
      const container = self.getElementsByClassName('sk-generic-card-container')[0];
      this.renderer.setStyle(container, 'height', this.cssCustomHeight);
    }
    if (this.cssCustomWidth) {
      const container = self.getElementsByClassName('sk-generic-card-container')[0];
      this.renderer.setStyle(container, 'width', this.cssCustomWidth);
    }
    if (this.imageItem) {
      let imgUriResult: string | null = null;
      const item = this.imageItem;
      this.getImageFromItem([item]).then(async (imgUri: string | null) => {
        if (imgUri) {
          imgUriResult = imgUri;
        }
        if (this.allowRecursiveImageSearch) {
          const cachedItem = this.cacheService.category.value.find(cc => cc.id === item.id)
          const cachedItemParent = this.cacheService.category.value.find(cc => cc.id === cachedItem?.parentCategoryId);
          const cachedItemRoot = this.cacheService.content.value.find(crc => crc.id === cachedItem?.parentRootId);
          const rUri = await this.getImageFromItem([cachedItemParent, cachedItemRoot]);
          if (rUri) {
            imgUriResult = rUri;
          }
        }
      }).finally(() => {
        if (!this.imageUrl || this.imageUrl === '') {
          this.imageUrl = ((imgUriResult ? (environment.cmsUrl + imgUriResult) : (environment.domain + environment.placeholderImage))).replace('./', '/');
          this.cd.detectChanges();
        }
      });
    }
    this.cd.detectChanges();
  }

  private async getImageFromItem(items: (ISKRootCategory | ISKCategoryData)[]): Promise<string | null> {
    let result: string | null = null;
    items.forEach((item: ISKRootCategory | ISKCategoryData) => {
      if (!result) {
        const imageDataArr = item ? item.attributes.image?.data : null;
        const uri = (imageDataArr && imageDataArr.length > 0) ? (imageDataArr[0]?.attributes?.formats?.medium?.url) : null;
        result = uri;
      }
    })
    return result;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
