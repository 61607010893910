<div class="d-flex flex-column justify-content-center align-items-center user-select-none p-3">

  <div class="w-100 d-flex flex-row justify-content-center align-items-center p-2">
    <sk-svg-icon [cssElementSize]="'64px'" [icon]="'alert'"></sk-svg-icon>
    <h1 class="d-block" [innerText]="getTranslation('api_connection_error')"></h1>
  </div>

  <div class="w-100 d-flex flex-row p-2 mb-3">
    <div class="d-flex flex-column flex-grow-0"></div>
    <div class="d-flex flex-column flex-grow-1 fw-bold" [innerText]="getTranslation('api_connection_error_info')"></div>
  </div>

  <button [disabled]="counter > 0" class="w-100" mat-flat-button (click)="reloadPage()" color="primary">
    <span [innerText]="counter > 0 ? counter : getTranslation('api_connection_error_reload')"></span>
  </button>

</div>
