<ng-container *ngIf="rootCategory?.id">
  <div class="p-2">
    <div (click)="toggleExpansionState();" class="w-100 d-flex flex-row justify-content-between align-items-center mb-2 sk-side-menu-section-main-title-wrapper">
      <div #mainTitle class="sk-side-menu-section-main-title flex-grow-1" [innerText]="rootCategory.attributes.name"></div>
      <div class="expand-all-icon-container flex-shrink-0">
        <sk-svg-icon class="expand-all-icon" [class.expanded]="expanded" [cssIconSize]="'2em'" [icon]="'chevron-left'"></sk-svg-icon>
        <div class="expand-all-icon-overlay"></div>
      </div>
    </div>
    <ng-scrollbar [autoHeightDisabled]="false" [class.expanded]="expanded" [class.collapsed]="!expanded">
      <div #expansionContainer class="expansion-container" >
        <ng-container *ngFor="let category of rootCategory.attributes.child_categories.data; let idx = index">
          <div class="expansion-sub-container" #expansionSubContainer>
            <!-- Select all Button -->
            <div *ngIf="idx === 0" (click)="setItem('rootCategory', category, rootCategory)" [class.selected]="!selectedCategory && rootCategory?.id === selectedRootCategory?.id" class="sk-side-menu-section-item all-in-item">
              {{getTranslation('offers_select_all') + ' ' + rootCategory.attributes.name}}
              <sk-svg-icon *ngIf="!selectedCategory && rootCategory?.id === selectedRootCategory?.id" (click)="clearRootCategorySelection($event)" [icon]="'close'"></sk-svg-icon>
            </div>
            <!-- Category buttons -->
            <ng-container [ngTemplateOutlet]="recursiveListElement" [ngTemplateOutletContext]="{category, rootCategory, idx, depth: maxDepth}"></ng-container>
          </div>
        </ng-container>
      </div>
    </ng-scrollbar>
  </div>

  <!-- Recursive List Element -->
  <ng-template #recursiveListElement let-category='category' let-rootCategory='rootCategory' let-idx='idx' let-depth='depth'>
    <ng-container *ngIf="category && !hideCategory(category)">
      <ng-container *ngVar="category?.attributes?.child_categories?.data?.length as childCategoryCnt">
        <ng-container *ngVar="(depth <= maxDepth - 2) as deepDive">

          <div #container class="wrapper">
            <!-- Category Container Item-->
            <div *ngIf="category" class="sk-side-menu-section-recursive-container"
                 [class.invisible]="!deepDive && !expanded" [class.expandable]="!deepDive && childCategoryCnt > 0">

              <!-- Actual Item-->
              <div (click)="toggleSub(container, category, rootCategory, true)"
                   class="sk-side-menu-section-item"
                   [class.selected]="category.id === selectedCategory?.id"
                   [class.extended-font-weight]="depth === maxDepth - 3"
                   [class.expendable-sub-item]="depth >= maxDepth - 1">

                <!-- Prefix + Title-->
                <div [title]="category.attributes.name" class="text-nowrap overflow-hidden" [style.text-overflow]="'ellipsis'" [style.max-width]="'100%'" [class.selected]="">
                  <span class="dot" *ngIf="deepDive" [innerText]="'&#8226;'"></span>
                  <span>{{category.attributes.name}}</span><br/>
                </div>

                <!-- Offer Counter-->
                <ng-container *ngIf="!(category.id === selectedCategory?.id)">
                  <div [class.deep]="deepDive" [class.visually-hidden]="getVisSubItmCnt(category) <= 0" class="counter" [innerText]="getVisSubItmCnt(category)"></div>
                </ng-container>

                <!-- Close Btn -->
                <sk-svg-icon (click)="sideMenuItemCloseButtonClicked($event, selectedCategory, rootCategory)" *ngIf="category.id === selectedCategory?.id" [icon]="'close'"></sk-svg-icon>
              </div>

            </div>

            <!-- Container for Child Categories -->
            <div #childContainer *ngIf="category" class="child-container" [class.collapsed]="!deepDive">
              <div class="recursive-list-element-parent" *ngFor="let childCategory of category?.attributes?.child_categories?.data">
                <ng-container [ngTemplateOutlet]="recursiveListElement" [ngTemplateOutletContext]="{category: childCategory, rootCategory, idx, depth: depth - 1}"></ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>
