import { Injectable } from '@angular/core';
import {ISKCachedCategoryData, ISKCachedOfferData} from "../cache/cache.service";
import {BehaviorSubject} from "rxjs";
import {ISKCategoryData, ISKOfferData, ISKRootCategory} from "../content/content.service";

@Injectable({
  providedIn: 'root'
})
export class OffersService {

  public selectedOffer: BehaviorSubject<ISKCachedOfferData | ISKOfferData | undefined | null> = new BehaviorSubject<ISKCachedOfferData | ISKOfferData | undefined | null>(null);
  public selectedCategory: BehaviorSubject<ISKCategoryData | undefined | null> = new BehaviorSubject<ISKCategoryData | undefined | null>(null);
  public selectedRootCategory: BehaviorSubject<ISKRootCategory | undefined | null> = new BehaviorSubject<ISKRootCategory | undefined | null>(null);

  constructor() { }

  public findChildOffers(category: ISKCachedCategoryData | ISKCategoryData, depth = 5): ISKOfferData[] {
    if (depth > 0) {
      const offers: ISKOfferData[] = [];
      category?.attributes?.offers?.data?.forEach((offer: ISKOfferData) => {
        offers.push(offer);
      })
      category?.attributes?.child_categories?.data?.forEach((childCategory: ISKCategoryData) => {
        const childCategoryOffers = this.findChildOffers(childCategory, depth - 1);
        offers.push(...childCategoryOffers);
      });
      return offers;
    } else {
      return [];
    }
  }

}
