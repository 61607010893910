import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor() {
    this.disableConsoleInProduction();
  }

  disableConsoleInProduction(): void {
    if (environment.production) {
      console.log = function (): void { };
      console.debug = function (): void { };
    }
  }
}
