import {Component, Input} from '@angular/core';

@Component({
  selector: 'sk-button',
  templateUrl: './sk-button.component.html',
  styleUrls: ['./sk-button.component.scss']
})
export class SkButtonComponent {

  @Input() deactivated: boolean = false;

}
