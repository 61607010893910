import {Component, ElementRef, HostListener, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { UiService } from "../../services/ui/ui.service";
import {BehaviorSubject, Subscription} from "rxjs";
import { routes } from "../../app-routing.module";
import { DeviceDetectorService } from "ngx-device-detector";
import {Route, Router} from "@angular/router";
import { environment } from "../../../environments/environment";
import {ISKLocale, LocaleService} from "../../services/locale/locale.service";
import {ISKStaticPages, StaticPagesService} from "../../services/static-pages/static-pages.service";

@Component({
  selector: 'sk-top-bar',
  templateUrl: './sk-top-bar.component.html',
  styleUrls: ['./sk-top-bar.component.scss']
})
export class SkTopBarComponent implements OnInit, OnDestroy {

  public readonly env = environment;
  private _exceed = false;
  public set exceed(e) {
    this.$exceed.next(e);
    this._exceed = e;
  };
  public get exceed(): boolean {
    return this._exceed;
  }
  @Output() $exceed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public routes = routes;
  public topBarOffset = 0;
  public topBarHeight = 0;
  public locales: ISKLocale[] = [];
  public staticPageData: ISKStaticPages | undefined;
  public readonly isMobile = this.device.isMobile();
  private subscriptions: Subscription[] = [];

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const topRect = this.ui.topBarElement?.getBoundingClientRect();
    if (topRect) {
      this.topBarOffset = topRect.top || 0;
      this.topBarHeight = topRect.height || 0;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize($event: Event) {
    if (this._topBar) {
      this.exceed = this._topBar.scrollWidth > this._topBar.clientWidth;
    }
  }

  private _topBar: HTMLDivElement;
  @ViewChild('topBar') set topBar(elRef: ElementRef) {
    if (elRef && !this.topBar) {
      this._topBar = elRef.nativeElement;
      this.exceed = this._topBar.scrollWidth > this._topBar.clientWidth;
      const topRect = this.ui.topBarElement?.getBoundingClientRect();
      this.topBarOffset = topRect?.top || 0;
      this.topBarHeight = topRect?.height || 0;
    }
  }

  private _offsetTop = (this.elementRef.nativeElement as HTMLElement).getBoundingClientRect().top;
  public get offsetTop(): number {
    return this._offsetTop;
  }

  private _height = (this.elementRef.nativeElement as HTMLElement).getBoundingClientRect().height;
  public get height(): number {
    return this._height;
  }

  constructor(
    private readonly ui: UiService,
    private readonly device: DeviceDetectorService,
    private readonly router: Router,
    private readonly localeService: LocaleService,
    private readonly staticPagesService: StaticPagesService,
    public readonly elementRef: ElementRef
  ) {
    this.subscriptions.push(staticPagesService.staticPageData.subscribe((staticPageData: ISKStaticPages) => {
      this.staticPageData = staticPageData;
    }));
  }

  ngOnInit(): void {
    this.localeService.getLocalesFromApi().then((locales: ISKLocale[]) => {
      this.locales.push(...locales);
    });
  }

  public getTranslation(s: string): string {
    return this.localeService.getField(s);
  }

  public navigate(route: string): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([route]).then((value: boolean) => {
        window.scrollTo(0,0);
        if (!environment.production) {
          console.log(`[SkTopBarComponent] Navigation engaged: ${route} (Success: ${value})`);
        }
      })
    })
  }

  public getMenuItemTitle(route: Route, fallback = ''): string {
    const endpoint = route.data['endpoint'];
    const staticPage = endpoint ? this.staticPageData[endpoint] : null;
    let specialCaseTitle: string | undefined;
    // Special cases
    if (!endpoint) {
      if (route.path === 'landing') {
        specialCaseTitle = this.localeService.getField('start_page');
      } else if (route.path === 'offers') {
        specialCaseTitle = this.localeService.getField('offers_plural');
      }
    }
    return staticPage?.attributes?.title || specialCaseTitle || fallback;
  }

  public setLocale(locale: ISKLocale) {
    this.localeService.setCurrent(locale, false);
  }

  public getLocale(): ISKLocale {
    return this.localeService.getCurrent();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription => {
      subscription.unsubscribe();
    }));
  }

}
