import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, Renderer2} from '@angular/core';
import {
  ContentService,
  ISKCategoryData,
  ISKOfferData,
  ISKRootCategory
} from "../../../services/content/content.service";
import {CacheService, ISKCachedCategoryData} from "../../../services/cache/cache.service";
import {OffersService} from "../../../services/offers/offers.service";
import {Subscription} from "rxjs";
import {LocaleService} from "../../../services/locale/locale.service";
import {ActivatedRoute, Data, Router, UrlSegment} from "@angular/router";

@Component({
  selector: 'sk-side-menu-section',
  templateUrl: './sk-side-menu-section.component.html',
  styleUrls: ['./sk-side-menu-section.component.scss']
})
export class SkSideMenuSectionComponent implements OnInit {

  private _rootCategory: ISKRootCategory;
  @Input() set rootCategory(rootCategory: ISKRootCategory) {
    if (rootCategory && (rootCategory.id !== this._rootCategory?.id)) {
      this._rootCategory = null;
      this.cd.detectChanges();
      this._rootCategory = rootCategory;
      this.cd.detectChanges();
      console.log('[SkSideMenuSectionComponent]: Root Category changed: ', this._rootCategory);
    }
  };
  public get rootCategory(): ISKRootCategory {
    return this._rootCategory;
  }

  @Input() rootCategoryIndex: number;
  public expanded = false;
  public expandedDelayed = this.expanded;
  public readonly maxDepth = 5;
  public selectedRootCategory: ISKRootCategory | undefined | null = this.offersService.selectedRootCategory.value; // To avoid service access in template
  public selectedCategory: ISKCategoryData | undefined | null = this.offersService.selectedCategory.value; // To avoid service access in template
  public selectedOffer: ISKOfferData | undefined | null = this.offersService.selectedOffer.value; // To avoid service access in template
  private readonly subscriptions: Subscription[] = [];

  constructor(
    private readonly cacheService: CacheService,
    private readonly contentService: ContentService,
    private readonly offersService: OffersService,
    private readonly renderer: Renderer2,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly localeService: LocaleService,
    private readonly cd: ChangeDetectorRef
  ) {
    cd.detach();
  }

  ngOnInit(): void {
    if (this.rootCategoryIndex === 0) {
      this.toggleExpansionState('expand').then(() => {
      });
    }
    this.subscribeToCaches().then(() => {
      this.cd.detectChanges();
    })
  }

  public getVisSubItmCnt(c: ISKCategoryData): number {
    const s: number[] = [];
    const cc = this.cacheService.category.value.find(cc => cc.id === c.id);
    cc?.attributes?.child_categories?.data?.forEach((child: ISKCategoryData) => {
      if (child?.attributes?.child_categories?.data?.length > 0 || child?.attributes?.offers?.data?.length > 0) {
          s.push(child.id);
      }
    });
    return s.length;
  }

  public getTranslation(s: string): string {
    return this.localeService.getField(s);
  }

  public hideCategory(c: ISKCategoryData): boolean {
    const cc = this.cacheService.category.value.find(cc => cc.id === c.id);
    const offers = this.offersService.findChildOffers(cc);
    return !offers || offers.length < 1;
  }

  private async subscribeToCaches() {
    // Subscription on selected root category change
    this.subscriptions.push(this.offersService.selectedRootCategory.subscribe((selectedRootCategory: ISKRootCategory) => {
      this.selectedRootCategory = selectedRootCategory;
      this.cd.detectChanges();
    }));
    // Subscription on selected category change
    this.subscriptions.push(this.offersService.selectedCategory.subscribe((selectedCategory: ISKCategoryData) => {
      this.selectedCategory = selectedCategory;
      this.cd.detectChanges();
    }));
    // Subscription on selected offer change
    this.subscriptions.push(this.offersService.selectedOffer.subscribe((selectedOffer: ISKOfferData) => {
      this.selectedOffer = selectedOffer;
      this.cd.detectChanges();
    }));
  }

  public clearRootCategorySelection($event: Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    this.offersService.selectedRootCategory.next(null);
    this.offersService.selectedCategory.next(null);
  }

  public sideMenuItemCloseButtonClicked($event: Event, category: ISKCategoryData, rootCategory: ISKRootCategory) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    const cachedCategory = this.cacheService.category.value.find(cc => cc.id === category.id);
    const categoryParent = this.cacheService.category.value.find(cc => cc.id === cachedCategory.parentCategoryId);
    this.selectedCategory = categoryParent;
    this.setItem('category', categoryParent, rootCategory);
  }
  //
  // public clearCategorySelection($event: Event) {
  //   $event.preventDefault();
  //   $event.stopImmediatePropagation();
  //   this.offersService.selectedCategory.next(null);
  // }
  //
  // public noData(category: ISKCachedCategoryData | ISKCategoryData) {
  //   const noOffers = category?.attributes?.offers?.data?.length;
  //   let noChildOffers = 0;
  //   let noChildChildren = 0;
  //   category?.attributes?.child_categories?.data?.forEach((child: ISKCategoryData) => {
  //     noChildOffers += child?.attributes?.child_categories?.data?.length;
  //     noChildChildren += child?.attributes?.child_categories?.data?.length;
  //   });
  //   console.log(noOffers, noChildOffers, noChildChildren);
  //   return (noOffers + noChildOffers + noChildChildren === 0);
  // }

  public setItem(type: 'rootCategory' | 'category' | 'offer', item: ISKRootCategory | ISKCategoryData | ISKOfferData, root: ISKRootCategory) {
    console.log(`[SkSideMenuSectionComponent] setItem ${type}`, item)
    this.offersService.selectedOffer.next(null);
    switch (type) {
      case "rootCategory":
        this.offersService.selectedRootCategory.next(root);
        this.offersService.selectedCategory.next(null);
        break;
      case "category":
        this.offersService.selectedRootCategory.next(root);
        this.offersService.selectedCategory.next(item as ISKCategoryData);
        break;
      case "offer":
        this.offersService.selectedRootCategory.next(null);
        this.offersService.selectedCategory.next(null);
        break;
    }
  }

  public toggleSub(container: HTMLDivElement, category?: ISKCategoryData, rootCategory?: ISKRootCategory, topCat?: boolean, errCnt?: number) {
    try {
      let earlyToggle = false;
      const childContainer = container.getElementsByClassName('child-container')[0] as HTMLDivElement;
      console.log(childContainer);
      if (childContainer) {
        const isCollapsed = childContainer.className.toLowerCase().includes('collapsed');
        if (!isCollapsed) {
          this.renderer.removeClass(childContainer, 'collapsed');
          this.renderer.addClass(childContainer, 'collapsed');
          const cachedCategory: ISKCachedCategoryData = this.cacheService.category.value.find(cc => cc.id === category.id);
          earlyToggle = true;
          this.setItem('category', cachedCategory, rootCategory);
        } else {
          this.renderer.removeClass(childContainer, 'collapsed');
        }
      }
      if (!earlyToggle && category && rootCategory) {
        this.setItem('category', category, rootCategory);
      }
    } catch (err) {
      console.warn(err);
      if (errCnt < 2) {
        this.toggleSub(container, category, rootCategory, null, errCnt);
      }
    }
  }

  public async toggleExpansionState(force?: 'expand' | 'collapse'): Promise<void> {
    if (!force) {
      this.expanded = !this.expanded;
    }
    else {
      switch (force) {
        case "collapse":
          this.expanded = false;
          break;
        case "expand":
          this.expanded = true;
          break;
      }
    }
    this.cd.detectChanges();
    setTimeout(() => {
      this.expandedDelayed = this.expanded;
      this.cd.detectChanges();
    },  this.expanded ? 333 : 0);
  }

}
