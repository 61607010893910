import {Component, OnInit} from '@angular/core';
import {LocaleService} from "../../services/locale/locale.service";


@Component({
  selector: 'sk-dialog-api-error',
  templateUrl: './sk-dialog-api-error.component.html',
  styleUrls: ['./sk-dialog-api-error.component.scss']
})
export class SkDialogApiErrorComponent {

  public counter = 5;
  private readonly countdown: NodeJS.Timer;

  constructor(
    private readonly localeService: LocaleService
  ) {
    this.countdown = setInterval(() => {
      this.counter--;
      if (this.counter <= 0) {
        clearInterval(this.countdown);
      }
    }, 1000);
  }

  public getTranslation(s: string): string {
    return this.localeService.getField(s);
  }

  public reloadPage(): void {
    location.reload();
  }

}
