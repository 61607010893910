<sk-carousel *ngIf="!mobile" [highlights]="highlights" class="d-none d-md-block"></sk-carousel>
<sk-view-container-content class="w-100 mx-auto" [style.max-width]="ui.pageMaxWidth">

  <img *ngIf="!mobile" class="landing-logo mx-auto d-none d-md-block" [src]="logoImgSrc" alt="SPORTKULTOUR">

<!--  <div class="w-100 d-flex flex-row justify-content-center align-items-center">-->
<!--    <div *ngFor="let locale of locales" class="d-block px-1 user-select-none" (click)="setLocale(locale)">{{locale.code}}</div>-->
<!--  </div>-->

  <sk-search></sk-search>

  <div id="landing-intro">

    <ng-container *ngIf="highlightsPadded && highlightsPadded.length > 0" >
      <div [style.max-width]="'100%'" class="d-flex flex-row justify-content-between flex-wrap">
        <div [style.max-width]="'100vw'" class="row">
          <ng-container *ngFor="let highlight of highlightsPadded">

          <!-- If highlight is available -->
            <div class="col-sm-6 col-md-4 col-lg-3 g-3">
              <ng-container *ngIf="highlight.type === 'offer'">
                <sk-generic-card *ngVar="(highlight?.highlightObject?.attributes?.name || '') as title"
                                 class="d-block sk-highlight-card user-select-none hover-transform"
                                 [imageUrl]="highlight.image" [cardTitle]="title" [cssContentPadding]="'.75em'"
                                 [cssCustomWidth]="'100%'">
                    <h5 [innerText]="getTranslation('offers_starting_at')"></h5>
                    <h4 [innerText]="utils.getPriceDisplay(getLowestPriceForHighlight(highlight))"></h4>

                  <div class="d-flex w-100 justify-content-center align-items-center">
                    <button class="w-100" mat-flat-button (click)="navToOffers(highlight)" color="primary">
                      <span [innerText]="getTranslation('offers_book_now')"></span>
                    </button>
                  </div>

                </sk-generic-card>
              </ng-container>

            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</sk-view-container-content>
