<div *ngIf="pageData" class="sk-static-page-container">

  <h1 class="mb-4 user-select-none" [innerText]="pageData.title"></h1>

  <markdown [data]="pageData['text']"></markdown>
  <img *ngIf="image" class="w-100 mb-3" [src]="image" alt="Agenturprofil">
  <markdown [data]="pageData['description']"></markdown>

  <!-- Contacts page -->
  <ng-container *ngIf="contacts?.length > 0">
    <div class="row my-2">
      <div *ngFor="let contact of contacts" class="contact col-md-6">
        <div class="p-1">
          <div class="contact-inner">
            <span class="fw-bold">{{contact.name}}</span><br />
            <span>{{contact.title}}</span><br />
            <span>{{contact.mail}}</span><br />
            <span>{{contact.fax}}</span><br />
            <span>{{contact.phone}}</span><br />
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Partner logos -->
  <ng-container *ngIf="logoImages && logoImages.length > 0">
    <div class="row w-100 my-2 p-2 partner-logos">
      <div class="partner-logo position-relative col-sm-4 col-md-3" *ngFor="let logoImage of logoImages">
        <img [src]="logoImage" [alt]="logoImage.split('/').reverse()[0]">
      </div>
    </div>
  </ng-container>

</div>
